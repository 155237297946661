import React, { useEffect, useContext, useState } from 'react';

import Table from 'components/ui/Table';
import AppContext from 'context/AppContext';
import { SITE_TITLE } from 'constants/main';
import SearchToolbar from 'components/ui/SearchToolbar';
import {
  documentSetTitle,
  getPriceFormat,
  getDate,
  getValueForSearch,
  exportToXlsx,
} from 'helpers';
import StatisticDetailsBackButton from 'components/StatisticDetailsBackButton';
import UiContext from 'context/UiContext';
import TerminalContent from 'components/TerminalContent';

const PAGE_TITLE = 'Ночные искассации терминалов';
const TABLE_HEADER = [
  { label: 'Номер терминала', field: 'IDTerminal' },
  { label: 'Компания', field: 'Сompany' },
  { label: 'Сумма', field: 'SumInEveryday' },
  { label: 'Адрес терминала', field: 'Address' },
  { label: 'Сумма в терминале на текущий момент', field: 'SumNow' },
];

const Night = () => {
  const date = getDate('date');
  const { getCashNight } = useContext(AppContext);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setBreadcrumbs } = useContext(UiContext);

  useEffect(() => {
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    setBreadcrumbs([{ name: PAGE_TITLE }]);

    const newData = [];
    getCashNight().then((res) => {
      if (res?.Terminals) {
        res.Terminals.forEach((item) => {
          let itemData = {};
          TABLE_HEADER.forEach(({ field }) => {
            itemData[field] = getValue(field, item[field]);
          });

          newData.push(itemData);
        });
        setTableFilterData(newData);
        setTableData(newData);
      }
      setIsLoading(false);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValue = (field, value) => {
    if (field === 'SumInEveryday' || field === 'SumNow') {
      return getPriceFormat(Number(value));
    }

    return value;
  };

  const handleSearch = (search) => {
    const formatedSearch = search.toLowerCase();
    if (search !== '') {
      const filteredItems = tableData.filter(
        (item) =>
          getValueForSearch(item.IDTerminal).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.Company).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.Address).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.SumInEveryday).indexOf(formatedSearch) !==
            -1 ||
          getValueForSearch(item.SumNow).indexOf(formatedSearch) !== -1
      );
      setTableFilterData(filteredItems);
    } else setTableFilterData(tableData);
  };

  const handleDownload = () => {
    const cols = [
      { width: '10' },
      { width: '25' },
      { width: '25' },
      { width: '20' },
      { width: '20' },
    ];
    exportToXlsx(cols, 'resTable', PAGE_TITLE + ' на  ' + date);
  };

  const getContent = () => (
    <>
      <SearchToolbar
        handleSearch={handleSearch}
        handleDownload={handleDownload}
      />
      <div id="resTable" style={{ display: 'none' }}>
        <Table
          tableHead={TABLE_HEADER}
          tableData={tableFilterData}
          showPaging={false}
        />
      </div>
      <Table
        tableHead={TABLE_HEADER}
        tableData={tableFilterData}
        showPaging={true}
        pageSize={30}
      />
    </>
  );

  return (
    <>
      <StatisticDetailsBackButton title={`${PAGE_TITLE} на ${date}`} />
      <div>
        <TerminalContent
          content={getContent()}
          isLoading={isLoading}
          tableData={tableData}
        />
      </div>
    </>
  );
};

export default Night;
