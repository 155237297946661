import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { checkDirection, getClassNames, getTableCellAlign } from 'helpers';
import { useTable } from 'hooks';
import Pagination from 'components/ui/Pagination';
import TableSortLabel from 'components/ui/TableSortLabel';

const BasicTable = ({
  tableData,
  tableHead,
  pageSize,
  showPaging,
  tableSort,
  tableFooter,
}) => {
  const {
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    order,
    orderBy,
    stableSort,
    createSortHandler,
  } = useTable(pageSize);

  const tableFormattedData = useMemo(() => {
    return !tableSort ? tableData : stableSort(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, tableSort, tableData]);

  return (
    <>
      <div className="flex flex-col">
        <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 ">
            <thead>
              <tr>
                {tableHead.map((row, key) => (
                  <th
                    key={key}
                    align={row?.align || 'left'}
                    data-id-cell={row.field}
                    className={getClassNames(
                      row.align ? `text-${row.align}` : 'text-left',
                      'kassa24-table-th'
                    )}
                  >
                    {row.sort === false || tableSort === false ? (
                      row.label
                    ) : (
                      <TableSortLabel
                        active={orderBy === row.field}
                        direction={checkDirection(row, orderBy, order)}
                        onClick={createSortHandler(row.field)}
                      >
                        {row.label}
                      </TableSortLabel>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody
              className="bg-white divide-y divide-gray-200 "
              aria-label="tableBody"
            >
              {showPaging
                ? tableFormattedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, i) => {
                      return (
                        <tr
                          key={i}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          {tableHead.map((val, key) => {
                            return (
                              <td
                                key={key}
                                className={getClassNames(
                                  `text-${getTableCellAlign(item, key)}`,
                                  'kassa24-table-td'
                                )}
                              >
                                {item[val.field]}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                : tableFormattedData.map((item, i) => {
                    return (
                      <tr key={i}>
                        {tableHead.map((val, key) => {
                          return (
                            <td
                              key={key}
                              style={{
                                color: item?.colors?.[key] || '',
                              }}
                              className={getClassNames(
                                `text-${getTableCellAlign(item, key)}`,
                                'kassa24-table-td'
                              )}
                            >
                              {item[val.field]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
            </tbody>
            {tableFooter && (
              <tfoot>
                {tableFooter.map((item, i) => {
                  return (
                    <tr key={i}>
                      {tableHead.map((val, key) => {
                        return (
                          <td
                            key={key}
                            style={{
                              color: item.colors ? item.colors[key] : '',
                            }}
                            align={getTableCellAlign(item, key)}
                          >
                            {item[val.field]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tfoot>
            )}
          </table>
        </div>
      </div>

      {showPaging && (
        <Pagination
          labelRowsPerPage="Количество на странице: "
          pageSizeOptions={['30', '50', '90']}
          total={50}
          pageSize={rowsPerPage}
          page={page + 1}
          onPageChange={handleChangePage}
          onShowSizeChange={handleChangeRowsPerPage}
          labelDisplayedRows={(total, [from, to]) =>
            `Показано ${from}-${to} из ${total}`
          }
          showSizeChanger={true}
        />
      )}
    </>
  );
};

BasicTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  tableHead: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  showPaging: PropTypes.bool,
  tableSort: PropTypes.bool,
  tableFooter: PropTypes.array,
};

BasicTable.defaultProps = {
  pageSize: 30,
  showPaging: false,
  tableSort: false,
  tableFooter: null,
};

export default BasicTable;
