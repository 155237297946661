import React, { useEffect, useContext, useState } from 'react';

import SearchToolbar from 'components/ui/SearchToolbar';
import Table from 'components/ui/Table';
import { SITE_TITLE } from 'constants/main';
import {
  documentSetTitle,
  getDate,
  getValueForSearch,
  exportToXlsx,
} from 'helpers';
import AppContext from 'context/AppContext';
import StatisticDetailsBackButton from 'components/StatisticDetailsBackButton';
import TerminalContent from 'components/TerminalContent';
import UiContext from 'context/UiContext';

const PAGE_TITLE = 'Убыточные терминалы';
const TABLE_HEADER = [
  { label: 'Ид агента', field: 'IDAgents' },
  { label: 'Номер терминала', field: 'TerminalID' },
  { label: 'Адрес терминала', field: 'Address' },
  { label: 'Терминал', field: 'TerminalName' },
  { label: 'Сумма', field: 'Summa' },
  { label: 'Дата', field: 'DateFirst' },
];

const Problems = () => {
  const { getProblemsTerminal } = useContext(AppContext);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setBreadcrumbs } = useContext(UiContext);
  const [tableFilterData, setTableFilterData] = useState([]);

  const date = getDate('date');
  const dateMonth = '01.' + getDate('myear');

  useEffect(() => {
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    setBreadcrumbs([{ name: PAGE_TITLE }]);

    const newData = [];
    getProblemsTerminal().then((res) => {
      if (res?.data) {
        res.data.forEach((item) => {
          let itemData = {
            IDAgents: getValue('IDAgents', item.IDAgents),
            TerminalID: getValue('TerminalID', item.TerminalID.Int64),
            TerminalName: getValue('TerminalName', item.TerminalName.String),
            Address: getValue('Address', item.Address.String),
            Summa: getValue('Summa', item.Summa.String),
            DateFirst: getValue('DateFirst', item.DateFirst.String),
          };

          newData.push(itemData);
        });
        setTableFilterData(newData);
        setTableData(newData);
      }
      setIsLoading(false);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (search) => {
    const formatedSearch = search.toLowerCase();
    if (search !== '') {
      const filteredItems = tableData.filter(
        (item) =>
          getValueForSearch(item.TerminalID).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.TerminalName).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.Address).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.IDAgents).indexOf(formatedSearch) !== -1
      );
      setTableFilterData(filteredItems);
    } else setTableFilterData(tableData);
  };

  const handleDownload = () => {
    const cols = [
      { width: '10' },
      { width: '25' },
      { width: '25' },
      { width: '20' },
      { width: '20' },
    ];
    exportToXlsx(cols, 'resTable', PAGE_TITLE + ' на  ' + date);
  };

  const getValue = (field, value) => {
    if (field === 'DateFirst') {
      return getDate('date', value);
    }
    return value;
  };

  const getContent = () => (
    <>
      <SearchToolbar
        handleSearch={handleSearch}
        handleDownload={handleDownload}
      />
      <div id="resTable" style={{ display: 'none' }}>
        <Table
          tableHead={TABLE_HEADER}
          tableData={tableFilterData}
          showPaging={false}
        />
      </div>
      <Table
        tableHead={TABLE_HEADER}
        tableData={tableFilterData}
        showPaging={true}
        pageSize={30}
      />
    </>
  );

  return (
    <>
      <StatisticDetailsBackButton
        title={`${PAGE_TITLE} в период с ${dateMonth} по ${date}`}
      />

      <div>
        <TerminalContent
          content={getContent()}
          isLoading={isLoading}
          tableData={tableData}
        />
      </div>
    </>
  );
};

export default Problems;
