import React, { useEffect, useState, useContext } from 'react';

import Link from 'components/ui/Link';
import Table from 'components/ui/Table';
import AppContext from 'context/AppContext';
import { getPriceFormat, getDate } from 'helpers';
import {
  TERMINAL_IDLE_LINK,
  TERMINAL_NIGHT_LINK,
  TERMINAL_CASHTECHNIK_LINK,
} from 'constants/main';

const TABLE_HEADER_CELLS = [
  { label: 'Инкассация', field: 'subTitle' },
  { label: getDate('date'), field: 'withDate' },
  { label: 'Нет даты', field: 'withoutDate' },
];

const getFieldValue = (data, fieldName) => (data && data[fieldName]) ?? '-';
// Percentage calculation
const percentCalc = (a, b) => Math.floor((a / b) * 100) || 0;
// Color calculation
const percentColor = (a) => (a <= 50 ? '#e74c3c' : '#32be3f');
// Default data so that there is no error
const defaultCash = {
  count: 0,
  sum: 0,
  inCount: 0,
  inSum: 0,
  evCount: 0,
  evSum: 0,
  oldCount: 0,
  oldSum: 0,
  dateReport: '2022-09-22T00:00:00Z',
  lastDate: '',
  firstTime: '',
  lastTime: '',
};
const defaultData = {
  login: '',
  idAgents: 0,
  currentCash: defaultCash,
  storyCash: defaultCash,
};

const generateEncashmentTableData = (data = defaultData) => {
  const { currentCash, storyCash } = data;
  const sum = percentCalc(currentCash.inSum, currentCash.sum);
  const storeSum = percentCalc(storyCash.inSum, storyCash.sum);
  const count = percentCalc(currentCash.inCount, currentCash.count);
  const storeCount = percentCalc(storyCash.inCount, storyCash.count);
  const colorSum = ['', percentColor(sum), percentColor(storeSum)];
  const colorCount = ['', percentColor(count), percentColor(storeCount)];
  return [
    {
      subTitle: '% от суммы',
      withDate: currentCash ? `${sum}%` : '-',
      withoutDate: storyCash ? `${storeSum}%` : '-',
      colors: colorSum,
    },
    {
      subTitle: 'Кол-во проинкассированных ACO',
      withDate: currentCash
        ? `${currentCash.inCount} из ${currentCash.count} (${count}%)`
        : '-',
      withoutDate: storyCash ? `${storeCount}%` : '-',
      colors: colorCount,
    },
    {
      subTitle: 'Время первой инкассации',
      withDate: getFieldValue(currentCash, 'firstTime'),
      withoutDate: getFieldValue(storyCash, 'firstTime'),
    },
    {
      subTitle: 'Время последней инкассации',
      withDate: getFieldValue(currentCash, 'lastTime'),
      withoutDate: getFieldValue(storyCash, 'lastTime'),
    },
    {
      subTitle: (
        <Link href={TERMINAL_NIGHT_LINK} name="Кол-во ночной инкассации" />
      ),
      withDate: getFieldValue(currentCash, 'evCount'),
      withoutDate: getFieldValue(storyCash, 'evCount'),
    },
    {
      subTitle: (
        <Link href={TERMINAL_NIGHT_LINK} name="Сумма ночной инкассации" />
      ),
      withDate: getFieldValue(currentCash, 'evSum'),
      withoutDate: getFieldValue(storyCash, 'evSum'),
    },
    {
      subTitle: (
        <Link
          href={TERMINAL_IDLE_LINK}
          name="Кол-во давно не инкассированных"
        />
      ),
      withDate: getFieldValue(currentCash, 'oldCount'),
      withoutDate: getFieldValue(storyCash, 'oldCount'),
    },
    {
      subTitle: (
        <Link href={TERMINAL_IDLE_LINK} name="Сумма давно не инкассированных" />
      ),
      withDate: data ? getPriceFormat(currentCash.oldSum) : 0,
      withoutDate: data ? getPriceFormat(storyCash.oldSum) : 0,
    },
    {
      subTitle: (
        <Link href={TERMINAL_IDLE_LINK} name="Самая давняя инкассация" />
      ),
      withDate: currentCash
        ? getDate('date', currentCash.lastDate, '', true)
        : '-',
      withoutDate: storyCash?.lastDate
        ? getDate('date', storyCash.lastDate, '', true)
        : 'Дата не указана',
    },
    {
      subTitle: (
        <Link href={TERMINAL_CASHTECHNIK_LINK} name="Инкассация по техникам" />
      ),
      withDate: '',
      withoutDate: '',
    },
  ];
};

const EncashmentStatistic = () => {
  const [tableData, setTableData] = useState(generateEncashmentTableData());
  const { fetchEncashmentStatistics } = useContext(AppContext);

  useEffect(() => {
    fetchEncashmentStatistics().then(({ data: { data } }) => {
      setTableData(generateEncashmentTableData(data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        tableHead={TABLE_HEADER_CELLS}
        tableData={tableData}
        tableSort={false}
      />
    </>
  );
};

export default EncashmentStatistic;
