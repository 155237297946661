import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import * as yup from 'yup';

import { getFieldsYup } from 'helpers';
import AppContext from 'context/AppContext';
import { useUser } from 'hooks';
import Input from 'components/ui/Input';
import Editor from 'components/ui/Editor';
import Button from 'components/ui/Button';

const FIELDS = [
  { name: 'title', type: 'text', label: 'Заголовок', required: true },
  { name: 'text', type: 'textarea', label: 'Текст', required: false },
];

const ERR_FIELD_TEXT = 'Поле обязательно для заполнения';

const AddIdea = ({ handleCloseModal }) => {
  const [editedText, setEditedText] = useState('');
  const { addNewIdea } = useContext(AppContext);
  const { userInfo } = useUser();
  const validationSchema = yup.object(getFieldsYup(FIELDS));
  const initialValues = {
    title: '',
    text: '',
  };

  const sendIdea = (values) => {
    values.text = editedText;
    values.directorId = Number(userInfo.userId);
    values.fullname = userInfo.userName;

    addNewIdea(values);
    handleCloseModal();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: sendIdea,
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="">
          {FIELDS.map(({ name, type, label, autoComplete }) =>
            type === 'textarea' ? (
              <div key={name} className="kassa24-textarea mt-3">
                <Editor
                  data={editedText}
                  onChange={setEditedText}
                  label={label}
                />
                {Boolean(touched[name] && !editedText) && (
                  <div className="kassa24-input-error">{ERR_FIELD_TEXT}</div>
                )}
              </div>
            ) : (
              <Input
                key={name}
                type={type}
                autoComplete={autoComplete}
                placeholder={label}
                {...getFieldProps(name)}
                error={Boolean(touched[name] && errors[name])}
              />
            )
          )}
          <Button className="mt-3" type="submit">
            Добавить
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};

AddIdea.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default AddIdea;
