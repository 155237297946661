import React, { Fragment, useRef } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';

const Modal = (props) => {
  const cancelButtonRef = useRef(null);

  const BootstrapDialogTitle = ({ children, onClose, ...other }) => {
    return (
      <div {...other}>
        {children}
        <Button variant="icon" onClick={onClose} className="kassa24-close-btn">
          <XIcon className="w-4 h-4" />
        </Button>
      </div>
    );
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.handleCloseModal}
      >
        <div className="kassa24-modal">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="kassa24-modal-content">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    {props.title && (
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900 mb-4"
                      >
                        <BootstrapDialogTitle onClose={props.handleCloseModal}>
                          {props.title}
                        </BootstrapDialogTitle>
                      </Dialog.Title>
                    )}
                    <div className="mt-2">
                      <div className="text-sm text-gray-500">
                        {props.children}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = {
  maxWidth: PropTypes.string,
  title: PropTypes.string,
  hideHeader: PropTypes.bool,
  withoutPadding: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  title: '',
  maxWidth: 'sm',
  hideHeader: false,
  withoutPadding: false,
  open: false,
  handleCloseModal: null,
  onClose: null,
};

export default Modal;
