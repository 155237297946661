import React from 'react';

import Skeleton from 'components/ui/Skeleton';

const LoadingContent = () => {
  return (
    <div className="rounded-lg shadow-lg ">
      <div className="px-5 py-5 border-b-2 border-gray-200">
        <Skeleton className="mt-2" width={48} />
        <Skeleton className="mt-2" width={60} />
        <Skeleton height={10} variant="rectangular" className="mt-2" />
      </div>
      <div className="flex justify-between px-4 py-4">
        <Skeleton variant="circle" className="h-10 w-10" />
        <Skeleton variant="circle" className="h-10 w-10" />
        <Skeleton variant="circle" className="h-10 w-10" />
        <Skeleton variant="circle" className="h-10 w-10" />
      </div>
    </div>
  );
};

export default LoadingContent;
