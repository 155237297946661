import React, { useContext } from 'react';
import { HomeIcon } from '@heroicons/react/solid';

import Link from 'components/ui/Link';
import UiContext from 'context/UiContext';

const Breadcrumbs = () => {
  const {
    state: { breadcrumbs },
  } = useContext(UiContext);

  return (
    <nav
      className="hidden bg-white border-b border-gray-200 lg:flex"
      aria-label="Breadcrumb"
    >
      <ul className="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
        <li className="flex" key="breadMain">
          <div className="flex items-center">
            <a href="/main" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {breadcrumbs.length > 0 &&
          breadcrumbs.map((item) => (
            <li key={`bread${item.name}`} className="flex">
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-6 h-full text-gray-200"
                  preserveAspectRatio="none"
                  viewBox="0 0 24 44"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                {item?.name && (
                  <Link
                    href={item.href || ''}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    name={item.name}
                  />
                )}
              </div>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
