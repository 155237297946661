import React from 'react';
import PropTypes from 'prop-types';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import { MAIN_MENU } from 'constants/main';
import Logo from 'assets/img/kassa24-white.svg';
import { redirectToPage, isMenuItemActive, getClassNames } from 'helpers';

const LOGO_TEXT = 'Кабинет директора';

const HeadMenu = ({ open }) => {
  return (
    <>
      <div className="flex items-center px-2 lg:px-0">
        <button onClick={() => redirectToPage('main')} data-testid="logoBtn">
          <div className="flex-shrink-0 flex items-center">
            <img className="h-8 w-auto" src={Logo} alt="kassa24" />
            <div className="text-white"> {LOGO_TEXT}</div>{' '}
          </div>
        </button>

        <div className="hidden lg:ml-8 lg:flex lg:space-x-4">
          {MAIN_MENU.map((item) => (
            <a
              key={item.name}
              href={item.link}
              onClick={() => redirectToPage(item.link)}
              className={getClassNames(
                isMenuItemActive(item.link) && 'bg-kassa24-active',
                'rounded-md py-2 px-3 text-sm font-medium text-white hover:bg-kassa24-active'
              )}
            >
              {item.name}
            </a>
          ))}
        </div>
      </div>
      {/* Mobile menu button */}
      <div className="order-3 lg:order-2 flex items-center lg:hidden">
        <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white ">
          <span className="sr-only">Open menu</span>
          {open ? (
            <XIcon
              className="block h-6 w-6"
              aria-hidden="true"
              data-testid="xicon"
            />
          ) : (
            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
          )}
        </Disclosure.Button>
      </div>
    </>
  );
};

HeadMenu.propTypes = {
  open: PropTypes.bool,
};

HeadMenu.defaultProps = {
  open: false,
};

export default HeadMenu;
