import React from 'react';
import { Disclosure } from '@headlessui/react';

import { useLocation } from 'react-router-dom';
import Breadcrumbs from 'components/ui/Breadcrumbs';
import HeadMenu from 'components/HeadMenu';
import HeadMobileMenu from 'components/HeadMobileMenu';
import HeadProfile from 'components/HeadProfile';

const Header = () => {
  const location = useLocation();

  return (
    <>
      <Disclosure as="nav" className="bg-kassa24-black" aria-label="Global">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
              <div className="grid grid-flow-col lg:flex justify-between h-16 items-center">
                <HeadMenu open={open} />
                <HeadProfile />
              </div>
            </div>
            <HeadMobileMenu open={open} />
          </>
        )}
      </Disclosure>
      {location.pathname !== '/main' && <Breadcrumbs />}
    </>
  );
};

export default Header;
