import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PlusIcon } from '@heroicons/react/outline';

import Modal from 'components/ui/Modal';
import AddComment from 'pages/Ideas/AddComment';
import Button from 'components/ui/Button';

const TITLE = 'Обсуждение';
const NO_FOUND = 'Нет коментариев';
const ADD_BTN_TEXT = 'Добавить комментарий';
const MODAL_TITLE = 'Добавление комментария';

const Comments = ({ data, handleAfterAddComment }) => {
  const [comments, setComments] = useState([]);
  const [open, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (data.comments) setComments(data.comments);
  }, [data]);

  return (
    <>
      <div
        data-open={open ? 'opened' : 'closed'}
        onClick={handleCloseModal}
        data-testid="modalAddComment"
      >
        <Modal
          handleCloseModal={handleCloseModal}
          title={MODAL_TITLE}
          open={open}
        >
          <AddComment
            handleCloseModal={handleCloseModal}
            id={data.id ? data.id : ''}
            handleAfterAddComment={handleAfterAddComment}
          />
        </Modal>
      </div>
      <div>
        <div className="lg:grid grid-cols-6 justify-between mt-5 items-center">
          <h5 className="col-span-5 text-xl font-bold">{TITLE}</h5>
          <Button
            className="lg:float-right mt-3 lg:mt-0"
            startIcon={<PlusIcon className="w-5 h-5" />}
            onClick={() => setShowModal(true)}
            size="auto"
          >
            {ADD_BTN_TEXT}
          </Button>
        </div>

        <div className="mt-5 shadow-lg rounded-lg">
          {comments.length > 0
            ? comments.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="border-b-2 border-gray-100"
                    aria-label="commentItem"
                  >
                    <div className="lg:grid grid-cols-3 px-5 py-5">
                      <div className="col-span-1">
                        <p>{item.fullname}</p>
                        <dd className="text-gray-400 text-sm">
                          {item.created}
                        </dd>
                      </div>
                      <div className="mt-3 lg:mt-0 col-span-2">
                        <div
                          className="text-gray-500 text-base"
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : NO_FOUND}
        </div>
      </div>
    </>
  );
};

Comments.propTypes = {
  data: PropTypes.object.isRequired,
  handleAfterAddComment: PropTypes.func.isRequired,
};

export default Comments;
