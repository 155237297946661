import {
  SET_SUCCESS_MESSAGE,
  SET_ERROR_MESSAGE,
  SET_OPEN_LEFT_MENU,
  SET_BREADCRUMBS,
} from 'constants/actionTypes';

const UiReducer = (state, action) => {
  switch (action.type) {
    case SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.successMessage };
    case SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };
    case SET_OPEN_LEFT_MENU:
      return { ...state, showLeftMenu: action.showLeftMenu };
    case SET_BREADCRUMBS:
      return { ...state, breadcrumbs: action.data };
    default:
      return state;
  }
};

export default UiReducer;
