const VERSION_PO = process.env.REACT_APP_VERSION_PO || '';
const isProd = VERSION_PO && Number(VERSION_PO.split('.')[0]);

const prod = () => {
  return {
    API_URL: process.env.REACT_APP_API_HOST_PROD,
  };
};

const dev = () => {
  return {
    API_URL: process.env.REACT_APP_API_HOST_TEST,
  };
};

if (isProd) {
  module.exports = prod();
} else {
  module.exports = dev();
}
