import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import TextEllipsis from 'react-text-ellipsis';
import {
  EyeIcon,
  ChatIcon,
  ThumbUpIcon,
  ThumbDownIcon,
} from '@heroicons/react/solid';

import AppContext from 'context/AppContext';
import { useUser } from 'hooks';
import Link from 'components/ui/Link';
import Button from 'components/ui/Button';
import { redirectToPage } from 'helpers';

const BlogPostCard = ({ item, handlerGetIdeas }) => {
  const title = item.title;
  const text = item.text;
  const likes = item?.like?.length ?? 0;
  const dislikes = item?.dislike?.length ?? 0;
  const views = item?.views?.length ?? 0;
  const comments = item.comments ? item.comments.length : 0;
  const link = 'ideas/' + item.id;
  const { setDislike, setLike } = useContext(AppContext);
  const [isLoadingLike, setIsLoadingLike] = useState(false);
  const [isLoadingDislike, setIsLoadingDislike] = useState(false);

  const { userInfo } = useUser();
  const sendUserInfo = {
    fullname: userInfo.userName,
    id: userInfo.id,
    login: userInfo.userLogin,
    directorId: userInfo.userId,
  };

  const goToDetail = () => {
    redirectToPage(link);
  };

  const setLikeHandler = () => {
    setIsLoadingLike(true);
    setLike(item.id, sendUserInfo).then(() => {
      handlerGetIdeas().then(() => {
        setIsLoadingLike(false);
      });
    });
  };

  const setDislikeHandler = () => {
    setIsLoadingDislike(true);
    setDislike(item.id, sendUserInfo).then(() => {
      handlerGetIdeas().then(() => {
        setIsLoadingDislike(false);
      });
    });
  };

  return (
    <div className="rounded-lg shadow-lg" aria-label="blogCard">
      <div className="px-5 py-5 border-b-2 border-gray-200">
        <p className="text-xs text-gray-500">{item.created}</p>
        <p className="text-xl leading-6 font-bold text-gray-900 py-4">
          {title}
        </p>

        <dd className="mt-2 text-base  lg:h-32">
          <TextEllipsis lines={4} debounceTimeoutOnResize={200}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </TextEllipsis>
          <Link href={link} name="Подробнее" className="py-2 block" />
        </dd>
      </div>

      <div className="flex justify-between px-4 py-4">
        <Button onClick={goToDetail} variant="icon">
          <>
            <EyeIcon className="w-5 h-5 " />
            <p data-testid="blogViews">{views}</p>
          </>
        </Button>
        <Button onClick={setLikeHandler} variant="icon">
          <>
            {isLoadingLike ? (
              <div className="dot-spin mx-4"></div>
            ) : (
              <>
                <ThumbUpIcon className="w-5 h-5" />
                <p data-testid="blogLikes">{likes}</p>
              </>
            )}
          </>
        </Button>
        <Button onClick={setDislikeHandler} variant="icon">
          <>
            {isLoadingDislike ? (
              <div className="dot-spin mx-4"></div>
            ) : (
              <>
                <ThumbDownIcon className="w-5 h-5" />
                <p data-testid="blogDislikes">{dislikes}</p>
              </>
            )}
          </>
        </Button>
        <Button onClick={goToDetail} variant="icon">
          <>
            <ChatIcon className="w-5 h-5" />
            <p data-testid="blogComments">{comments}</p>
          </>
        </Button>
      </div>
    </div>
  );
};

BlogPostCard.propTypes = {
  item: PropTypes.object.isRequired,
  handlerGetIdeas: PropTypes.func.isRequired,
};

export default BlogPostCard;
