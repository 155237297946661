import React from 'react';
import PropTypes from 'prop-types';

import { getClassNames } from 'helpers';

const TabsCustom = ({ value, tabs, handleChange }) => {
  const onChange = (e) => {
    handleChange(Number(e.target.value));
  };

  return (
    <>
      <div>
        <div className="sm:hidden">
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none  sm:text-sm rounded-md"
            defaultValue={value}
            onChange={onChange}
          >
            {tabs.map((tab, key) => (
              <option key={key} value={key}>
                {tab}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab, key) => (
                <div
                  key={key}
                  onClick={() => handleChange(key)}
                  className={getClassNames(
                    value === key && 'kassa24-tab-active',
                    'kassa24-tab-item'
                  )}
                  aria-label={`tab${key}`}
                >
                  {tab}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

TabsCustom.propTypes = {
  value: PropTypes.number.isRequired,
  tabs: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default TabsCustom;
