import React, { useEffect, useState, useContext } from 'react';

import SearchToolbar from 'components/ui/SearchToolbar';
import Table from 'components/ui/Table';
import { SITE_TITLE } from 'constants/main';
import {
  documentSetTitle,
  getDate,
  getDiffString,
  exportToXlsx,
  getValueForSearch,
} from 'helpers';
import AppContext from 'context/AppContext';
import StatisticDetailsBackButton from 'components/StatisticDetailsBackButton';
import TerminalContent from 'components/TerminalContent';
import UiContext from 'context/UiContext';

const PAGE_TITLE = 'Проблемные терминалы';
const TABLE_HEADER = [
  { label: 'Номер терминала', field: 'TerminalID' },
  { label: 'Название терминала', field: 'TerminalName' },
  { label: 'Адрес терминала', field: 'Address' },
  { label: 'Время последнего отклика', field: 'Dping' },
  { label: 'Техник', field: 'FullName' },
];

const NoConnect = () => {
  const date = getDate('date');
  const { getNoConnection } = useContext(AppContext);
  const [tableData, setTableData] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setBreadcrumbs } = useContext(UiContext);

  useEffect(() => {
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    setBreadcrumbs([{ name: PAGE_TITLE }]);

    const newData = [];

    getNoConnection().then((res) => {
      if (res?.data) {
        res.data.forEach((item) => {
          let itemData = {
            TerminalID: getValue('TerminalID', item.TerminalID.Int64),
            TerminalName: getValue('TerminalName', item.TerminalName.String),
            Address: getValue('Address', item.Address.String),
            Dping: getValue('Dping', item.Dping.String),
            FullName: getValue('FullName', item.FullName.String),
          };

          newData.push(itemData);
        });
        setTableFilterData(newData);
        setTableData(newData);
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValue = (field, value) => {
    if (field === 'Dping') {
      return getDateContent(value);
    }
    return value;
  };

  const getDateContent = (value) => {
    const diff = Math.floor(new Date() - new Date(value));
    const formattedData = getDate('full', value, '', true);
    const diffDay = getDiffString(diff / (1000 * 60 * 60 * 24));

    return (
      <>
        {formattedData}
        &nbsp;
        <span
          style={{
            color: '#e74c3c',
            textAlign: 'right',
            paddingLeft: '10px',
          }}
        >
          {diffDay}
        </span>
      </>
    );
  };

  const handleSearch = (search) => {
    const formatedSearch = search.toLowerCase();
    if (search !== '') {
      const filteredItems = tableData.filter(
        (item) =>
          getValueForSearch(item.TerminalID).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.TerminalName).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.Address).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.Dping).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.FullName).indexOf(formatedSearch) !== -1
      );
      setTableFilterData(filteredItems);
    } else setTableFilterData(tableData);
  };

  const handleDownload = () => {
    const cols = [
      { width: '10' },
      { width: '25' },
      { width: '25' },
      { width: '20' },
      { width: '20' },
    ];
    exportToXlsx(cols, 'resTable', PAGE_TITLE + ' на  ' + date);
  };

  const getContent = () => (
    <>
      <SearchToolbar
        handleSearch={handleSearch}
        handleDownload={handleDownload}
      />
      <div id="resTable" style={{ display: 'none' }}>
        <Table
          tableHead={TABLE_HEADER}
          tableData={tableFilterData}
          showPaging={false}
        />
      </div>

      <Table
        tableHead={TABLE_HEADER}
        tableData={tableFilterData}
        showPaging={true}
        tableSort={true}
        pageSize={30}
      />
    </>
  );

  return (
    <>
      <StatisticDetailsBackButton title={`${PAGE_TITLE} на ${date}`} />

      <div>
        <TerminalContent
          content={getContent()}
          isLoading={isLoading}
          tableData={tableData}
        />
      </div>
    </>
  );
};

export default NoConnect;
