import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { documentSetTitle } from 'helpers';
import { SITE_TITLE } from 'constants/main';
import Night from 'pages/Terminals/Night';
import Idle from 'pages/Terminals/Idle';
import Cashtechnik from 'pages/Terminals/Cashtechnik';
import Provider from 'pages/Terminals/Provider';
import NoConnect from 'pages/Terminals/NoConnect';
import TurnOver from 'pages/Terminals/TurnOver';
import Problems from 'pages/Terminals/Problems';
import NoPay from 'pages/Terminals/NoPay';

const Terminals = () => {
  const location = useLocation().pathname.split('/');
  const id = location[2];

  useEffect(() => {
    documentSetTitle(SITE_TITLE);
  }, []);

  const getComponent = () => {
    let component = '';
    switch (id) {
      case 'night':
        component = <Night />;
        break;
      case 'idle':
        component = <Idle />;
        break;
      case 'cashtechnik':
        component = <Cashtechnik />;
        break;
      case 'problems':
        component = <Problems />;
        break;
      case 'turnover':
        component = <TurnOver />;
        break;
      case 'noconnection':
        component = <NoConnect />;
        break;
      case 'provider':
        component = <Provider />;
        break;
      case 'nopay':
        component = <NoPay />;
        break;
      default:
        component = <Night />;
        break;
    }
    return component;
  };

  return getComponent();
};

export default Terminals;
