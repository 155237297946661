import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ data, onChange, label, ...other }) => {
  const editorRef = useRef(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  return (
    <ReactQuill
      ref={editorRef}
      value={data || ''}
      tabIndex={1}
      theme="snow"
      enabled={true}
      onChange={onChange}
      placeholder={label}
      readOnly={false}
      modules={modules}
      {...other}
    />
  );
};

Editor.propTypes = {
  data: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Editor;
