import React, { useContext, useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/outline';

import { documentSetTitle, sortData } from 'helpers';
import { SITE_TITLE } from 'constants/main';
import TabPanel from 'components/ui/TabPanel';
import TabsCustom from 'components/ui/TabsCustom';
import Button from 'components/ui/Button';
import AppContext from 'context/AppContext';
import BlogPostCard from 'pages/Ideas/BlogPostCard';
import Modal from 'components/ui/Modal';
import AddIdea from 'pages/Ideas/AddIdea';
import LoadingContent from './LoadingContent';
import TerminalContent from 'components/TerminalContent';
import UiContext from 'context/UiContext';
import PaginationCustom from 'components/ui/Pagination';

const PAGE_TITLE = 'Наши идеи';
const TABS = ['Идеи в обсуждении', 'Идеи в реализации', 'Отклоненные идеи'];
const MODAL_TITLE = 'Добавление идеи';
const ADD_BTN_TEXT = 'Добавить идею';
const BLOG_STATUSES = ['discussion', 'relise', 'canceled'];
const NO_FOUND = 'Ничего не найдено';

const Ideas = () => {
  let filterItems = [];

  const [value, setValue] = useState(0);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setBreadcrumbs } = useContext(UiContext);
  const { getIdeasList } = useContext(AppContext);
  const [open, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsOnPage, setItemsOnPage] = useState(20);

  useEffect(() => {
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    getIdeasListHandler();
    setBreadcrumbs([{ current: true, name: PAGE_TITLE }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const onSetPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleSetItemsOnPage = (e) => {
    setItemsOnPage(e.target.value);
  };

  const getIdeasListHandler = () => {
    getIdeasList().then((result) => {
      if (result?.blogs) setItems(sortData(result.blogs, 'created', 'desc'));
      setIsLoading(false);
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModalAfterAdd = () => {
    handleCloseModal();
    return getIdeasListHandler();
  };

  const getLoadingContent = () => {
    const resultLoading = [];

    for (let i = 0; i < 2; i++) {
      resultLoading.push(<LoadingContent key={i} />);
    }

    return resultLoading;
  };

  const getContent = () => (
    <>
      <TabsCustom tabs={TABS} value={value} handleChange={handleChange} />
      {TABS.map((_, i) => {
        filterItems = items.filter((blog) => blog.status === BLOG_STATUSES[i]);

        return (
          <TabPanel
            value={value}
            index={i}
            key={`tab${i}`}
            style={{ marginTop: '30px' }}
          >
            {filterItems.length > 0 ? (
              <div>
                <div className="lg:grid grid-cols-2 gap-6">
                  {filterItems
                    .slice((page - 1) * itemsOnPage, page * itemsOnPage)
                    .map((post) => (
                      <BlogPostCard
                        key={post.id}
                        item={post}
                        handlerGetIdeas={getIdeasListHandler}
                        isLoading={isLoading}
                      />
                    ))}
                </div>
                <div className="py-8">
                  <PaginationCustom
                    pageSize={itemsOnPage}
                    page={page}
                    total={filterItems.length}
                    onPageChange={onSetPage}
                    showSizeChanger={true}
                    onShowSizeChange={handleSetItemsOnPage}
                    showTotal={true}
                    labelDisplayedRows={(total, [from, to]) =>
                      `Показано ${from}-${to} из ${total}`
                    }
                    pageSizeOptions={['20', '40', '80']}
                    hideOnSinglePage={true}
                  />
                </div>
              </div>
            ) : (
              NO_FOUND
            )}
          </TabPanel>
        );
      })}
    </>
  );

  return (
    <>
      <div
        data-testid="modalAddIdea"
        data-open={open ? 'opened' : 'closed'}
        onClick={(handleCloseModal, handleCloseModalAfterAdd)}
      >
        <Modal
          handleCloseModal={handleCloseModal}
          title={MODAL_TITLE}
          open={open}
        >
          <AddIdea handleCloseModal={handleCloseModalAfterAdd} />
        </Modal>
      </div>
      <div className="lg:grid grid-cols-6 items-center pb-5">
        <h1 className="kassa24-h1-py-0 col-span-5 ">{PAGE_TITLE}</h1>
        <div className="col-span-1 pt-2 lg:pt-0">
          <Button
            startIcon={<PlusIcon className="w-5 h-5" />}
            onClick={() => setShowModal(true)}
            className="lg:float-right"
            size="auto"
          >
            {ADD_BTN_TEXT}
          </Button>
        </div>
      </div>
      <TerminalContent
        content={getContent()}
        isLoading={isLoading}
        tableData={items}
        loadingContent={
          <div className="lg:grid grid-cols-2 gap-6">{getLoadingContent()}</div>
        }
      />
    </>
  );
};

export default Ideas;
