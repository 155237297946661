import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = (props) => {
  const { type, onClick, variant, size, title, startIcon } = props;
  const buttonSize = size ? size : 'medium';

  const getClasses = () => {
    return classNames(
      `kassa24-button kassa24-button-${buttonSize}`,
      props?.className,
      { 'kassa24-icon-button': variant === 'icon' }
    );
  };

  return (
    <button
      type={type ? type : 'button'}
      onClick={onClick || null}
      className={getClasses()}
      title={title || null}
    >
      {startIcon && <span className="flex items-center pr-3">{startIcon}</span>}
      {props.children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  startIcon: PropTypes.object,
};

Button.defaultProps = {
  className: '',
  type: 'button',
  onClick: null,
  variant: '',
  size: 'medium',
  title: '',
  startIcon: null,
};

export default Button;
