import { useContext, useMemo } from 'react';
import UserContext from 'context/UserContext';

const useUser = () => {
  const context = useContext(UserContext);
  const {
    login,
    saveUser,
    getUserData,
    setUserFields,
    state: { userInfo, captcha },
    changePassword,
    getCaptcha,
  } = context;

  const isDirector = useMemo(
    () => userInfo.userType === 'd',
    [userInfo.userType]
  );

  return {
    userInfo,
    captcha,
    login,
    saveUser,
    getUserData,
    isDirector,
    setUserFields,
    changePassword,
    getCaptcha,
  };
};

export default useUser;
