import React, { useCallback, useEffect } from 'react';
import { CheckCircleIcon, XIcon, XCircleIcon } from '@heroicons/react/solid';

import { useAlert } from 'hooks';
import { getClassNames } from 'helpers';

const SnackbarComponent = () => {
  const { successMessage, errorMessage, setSuccessMessage, setErrorMessage } =
    useAlert();

  useEffect(() => {
    if (successMessage || errorMessage) {
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMessage, errorMessage]);

  const handleCloseSnackbar = useCallback(() => {
    setSuccessMessage('');
    setErrorMessage('');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {(successMessage || errorMessage) && (
        <div
          className={getClassNames(
            successMessage ? 'bg-green-200' : 'bg-red-200',
            'fixed rounded-md p-4 right-10 top-10 z-10'
          )}
          data-testid="snackMain"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              {successMessage ? (
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                  data-testid="successIcon"
                />
              ) : (
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                  data-testid="xIcon"
                />
              )}
            </div>
            <div className="ml-3">
              <p
                className={getClassNames(
                  successMessage ? 'text-green-800' : 'text-red-700',
                  'text-sm font-medium'
                )}
                data-testid="snackMessage"
              >
                {errorMessage || successMessage}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={handleCloseSnackbar}
                  type="button"
                  className={getClassNames(
                    successMessage ? 'text-green-500' : 'text-red-500',
                    'kassa24-snackbar-close-button '
                  )}
                  data-testid="snackButtonClose"
                >
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SnackbarComponent;
