import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Skeleton = ({ variant, height, width, className }) => {
  const getClasses = () => {
    return classNames(
      'kassa24-skeleton',
      `h-${height}`,
      `w-${width}`,
      className,
      {
        'rounded-lg': variant !== 'circle',
        'rounded-full': variant === 'circle',
        'h-5': !height,
      }
    );
  };

  return <div className={getClasses()}></div>;
};

Skeleton.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Skeleton.defaultProps = {
  variant: 'default',
  className: '',
  height: 5,
  width: 'full',
};

export default Skeleton;
