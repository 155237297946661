import React, { useEffect, useContext, useState } from 'react';

import SearchToolbar from 'components/ui/SearchToolbar';
import Table from 'components/ui/Table';
import { SITE_TITLE } from 'constants/main';
import {
  documentSetTitle,
  getDate,
  getPriceFormat,
  getValueForSearch,
  exportToXlsx,
} from 'helpers';
import AppContext from 'context/AppContext';
import StatisticDetailsBackButton from 'components/StatisticDetailsBackButton';
import TerminalContent from 'components/TerminalContent';
import UiContext from 'context/UiContext';

const PAGE_TITLE = 'Инкассация по техникам ';
const TABLE_HEADER = [
  { label: 'Ф.И.О техника', field: 'Fullname' },
  { label: '% от кол-ва', field: 'EvCount' },
  { label: '% от суммы', field: 'EvSum' },
  { label: 'Время первой', field: 'FirstTime' },
  { label: 'Время последней', field: 'LastTime' },
  { label: 'Кол-во ночной инкассации', field: 'Count' },
  { label: 'Кол-во давно неинкассированных', field: 'OldCount' },
  { label: 'Сумма в давно неинкассированных', field: 'OldSum' },
  { label: 'Самая давняя инкассация', field: 'LastDate' },
];

const Cashtechnik = () => {
  const { getCashTechnik } = useContext(AppContext);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableFilterData, setTableFilterData] = useState([]);
  const { setBreadcrumbs } = useContext(UiContext);

  useEffect(() => {
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    setBreadcrumbs([{ name: PAGE_TITLE }]);

    const newData = [];
    getCashTechnik().then((res) => {
      if (res.data) {
        res.data.forEach((item) => {
          let itemData = {};
          TABLE_HEADER.forEach(({ field }) => {
            if (field === 'EvCount') {
              itemData[
                field
              ] = `${item.EvCount} из ${item.InCount} (${item.Count}%)`;
            } else itemData[field] = getValue(field, item[field]);
          });

          newData.push(itemData);
        });
        setTableFilterData(newData);
        setTableData(newData);
        setIsLoading(false);
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValue = (field, value) => {
    switch (field) {
      case 'LastDate':
        return getDate('date', value, '', true);
      case 'EvSum':
      case 'OldSum':
        return getPriceFormat(Number(value));

      default:
        return value;
    }
  };

  const handleSearch = (search) => {
    const formatedSearch = search.toLowerCase();
    if (search !== '') {
      const filteredItems = tableData.filter(
        (item) =>
          getValueForSearch(item.Fullname).indexOf(formatedSearch) !== -1
      );
      setTableFilterData(filteredItems);
    } else setTableFilterData(tableData);
  };

  const handleDownload = () => {
    const cols = [
      { width: '20' },
      { width: '10' },
      { width: '10' },
      { width: '10' },
      { width: '10' },
      { width: '10' },
      { width: '10' },
      { width: '10' },
      { width: '10' },
    ];
    exportToXlsx(cols, 'resTable', PAGE_TITLE);
  };

  const getContent = () => (
    <>
      <SearchToolbar
        handleDownload={handleDownload}
        handleSearch={handleSearch}
      />
      <div id="resTable" style={{ display: 'none' }}>
        <Table
          tableHead={TABLE_HEADER}
          tableData={tableFilterData}
          showPaging={false}
        />
      </div>
      <Table
        tableHead={TABLE_HEADER}
        tableData={tableFilterData}
        showPaging={true}
        pageSize={30}
      />
    </>
  );

  return (
    <>
      <StatisticDetailsBackButton title={PAGE_TITLE} />
      <div>
        <TerminalContent
          isLoading={isLoading}
          content={getContent()}
          tableData={tableFilterData}
        />
      </div>
    </>
  );
};

export default Cashtechnik;
