import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EyeIcon,
  ChatIcon,
  ThumbUpIcon,
  ThumbDownIcon,
} from '@heroicons/react/solid';

import { documentSetTitle } from 'helpers';
import { SITE_TITLE } from 'constants/main';
import AppContext from 'context/AppContext';
import Comments from 'pages/Ideas/Comments';
import { useUser } from 'hooks';
import Button from 'components/ui/Button';
import UiContext from 'context/UiContext';

const IdeaDetail = () => {
  const location = useLocation().pathname.split('/');
  const id = location[2];
  const [detailInfo, setDetailInfo] = useState({});
  const { userInfo } = useUser();
  const { setBreadcrumbs } = useContext(UiContext);
  const { setDislike, setLike, getIdeaDetail, sendViewIdea } =
    useContext(AppContext);
  const sendUserInfo = {
    fullname: userInfo.userName,
    id: userInfo.id,
    login: userInfo.userLogin,
    directorId: userInfo.userId,
  };
  const [isLoadingLike, setIsLoadingLike] = useState(false);
  const [isLoadingDislike, setIsLoadingDislike] = useState(false);

  useEffect(() => {
    if (id) {
      handleGetIdeaDetail();
      sendViewIdea(id, sendUserInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const PAGE_TITLE = 'Наши идеи - ' + detailInfo.title;
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    setBreadcrumbs([
      { name: 'Наши идеи', href: 'ideas' },
      { name: detailInfo.title, href: '' },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailInfo]);

  const handleGetIdeaDetail = () => {
    return getIdeaDetail(id).then((res) => {
      setDetailInfo(res.data.blog);
    });
  };

  const setLikeHandler = () => {
    setIsLoadingLike(true);
    setLike(id, sendUserInfo).then(() => {
      handleGetIdeaDetail().then(() => {
        setIsLoadingLike(false);
      });
    });
  };

  const setDislikeHandler = () => {
    setIsLoadingDislike(true);
    setDislike(id, sendUserInfo).then(() => {
      handleGetIdeaDetail().then(() => {
        setIsLoadingDislike(false);
      });
    });
  };

  const handleAfterAddComment = () => {
    handleGetIdeaDetail();
  };

  return (
    <>
      <div className="rounded-lg shadow-lg ">
        <div className="px-5 py-5 border-b-2 border-gray-200">
          <h1 className="text-xl leading-6 font-bold text-gray-900 py-4">
            {detailInfo.title}
          </h1>
          <p className="text-xs text-gray-500">{detailInfo.created}</p>
          <dd className="mt-2 text-base lg:h-32">
            <div dangerouslySetInnerHTML={{ __html: detailInfo.text }} />
          </dd>
        </div>

        <div className="flex px-4 py-4">
          <Button variant="icon">
            <>
              <EyeIcon className="w-5 h-5" />
              <p data-testid="blogViews">{detailInfo?.views?.length ?? 0}</p>
            </>
          </Button>
          <Button onClick={setLikeHandler} variant="icon" className="ml-4">
            <>
              {isLoadingLike ? (
                <div className="dot-spin mx-4"></div>
              ) : (
                <>
                  <ThumbUpIcon className="w-5 h-5" />
                  <p data-testid="blogLikes">
                    {detailInfo?.like?.length ?? 0}
                  </p>{' '}
                </>
              )}
            </>
          </Button>
          <Button onClick={setDislikeHandler} variant="icon" className="ml-4">
            <>
              {isLoadingDislike ? (
                <div className="dot-spin mx-4"></div>
              ) : (
                <>
                  <ThumbDownIcon className="w-5 h-5" />
                  <p data-testid="blogDislikes">
                    {detailInfo?.dislike?.length ?? 0}
                  </p>
                </>
              )}
            </>
          </Button>
          <Button variant="icon" className="ml-4">
            <>
              <ChatIcon className="w-5 h-5" />
              <p data-testid="blogComments">
                {detailInfo?.comments?.length ?? 0}
              </p>
            </>
          </Button>
        </div>
      </div>
      <Comments
        data={detailInfo}
        handleAfterAddComment={handleAfterAddComment}
      />
    </>
  );
};

export default IdeaDetail;
