import React, { useEffect } from 'react';

import { SITE_TITLE } from 'constants/main';
import { documentSetTitle, getDate } from 'helpers';
import TerminalsStatistic from 'pages/MainPage/TerminalsStatistic';
import EncashmentStatistic from 'pages/MainPage/EncashmentStatistic';
import FinancialStatistics from 'pages/MainPage/FinancialStatistics';
import AsoStatistic from 'pages/MainPage/AsoStatistic';

const currentTime = getDate('time');
const currentDate = getDate('date');

const MainStatistic = () => {
  useEffect(() => {
    documentSetTitle(SITE_TITLE);
  }, []);

  return (
    <>
      <div>
        <h1 className="kassa24-h1">
          Статистика на {currentDate} {currentTime}
        </h1>
        <div className="lg:grid lg:grid-cols-2 lg:gap-6 ">
          <div className="lg:cols-span-1 shadow-lg rounded-lg relative">
            <EncashmentStatistic />
          </div>
          <div className="lg:col-span-1 pt-5 md:pt-0 ">
            <div className="shadow-lg rounded-lg relative">
              <TerminalsStatistic />
            </div>
            <div className="shadow-lg rounded-lg mt-5 pb-1 relative">
              <AsoStatistic />
            </div>
          </div>
        </div>

        <div className="shadow-lg rounded-lg mt-5 pb-1 relative">
          <FinancialStatistics />
        </div>
      </div>
    </>
  );
};

export default MainStatistic;
