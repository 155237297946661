import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import UiReducer from 'reducers/UiReducer';
import UiContext from 'context/UiContext';
import {
  SET_ERROR_MESSAGE,
  SET_SUCCESS_MESSAGE,
  SET_BREADCRUMBS,
} from 'constants/actionTypes';
import WithAxios from 'components/WithAxios';
import { getFromLocalStorage } from 'helpers';

const initialState = {
  showLoader: 0,
  successMessage: '',
  errorMessage: '',
  showLeftMenu: getFromLocalStorage('showLeftMenu'),
  breadcrumbs: [],
};

const UiProvider = (props) => {
  const [state, dispatch] = useReducer(UiReducer, initialState);

  const setSuccessMessage = (message) => {
    dispatch({
      type: SET_SUCCESS_MESSAGE,
      successMessage: message,
    });
  };

  const setErrorMessage = (message) => {
    dispatch({
      type: SET_ERROR_MESSAGE,
      errorMessage: message,
    });
  };

  const setBreadcrumbs = (data) => {
    dispatch({ type: SET_BREADCRUMBS, data });
  };

  return (
    <UiContext.Provider
      value={{
        state,
        dispatch,
        setSuccessMessage,
        setErrorMessage,
        setBreadcrumbs,
      }}
    >
      <WithAxios>{props.children}</WithAxios>
    </UiContext.Provider>
  );
};

UiProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default UiProvider;
