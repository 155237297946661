import React from 'react';
import PropTypes from 'prop-types';

import { ArrowLeftIcon } from '@heroicons/react/outline';

import { goToBackPage } from 'helpers';
import Button from 'components/ui/Button';

const StatisticDetailsBackButton = ({ title }) => {
  return (
    <div className="lg:grid grid-cols-6 items-center pb-5">
      <h1 className="kassa24-h1-py-0 col-span-5 ">{title}</h1>
      <div className="col-span-1 pt-2  lg:pt-0">
        <Button
          className="lg:float-right"
          variant="contained"
          startIcon={<ArrowLeftIcon className="w-5 h-5" />}
          onClick={() => goToBackPage(true)}
        >
          Назад
        </Button>
      </div>
    </div>
  );
};

StatisticDetailsBackButton.propTypes = {
  title: PropTypes.string.isRequired,
};

export default StatisticDetailsBackButton;
