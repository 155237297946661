import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { getFromLocalStorage, redirectToPage } from 'helpers';
import Snackbar from 'components/ui/Snackbar';

import Header from './Header';

const Layout = (props) => {
  const location = useLocation();
  const path = location.pathname;
  const userInfo = getFromLocalStorage('userInfo');
  const token = userInfo?.token;

  useEffect(() => {
    if (token && location.pathname === '/') {
      redirectToPage('main');
    }
    if (!token && location.pathname !== '/') redirectToPage('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Snackbar />
      {path !== '/' ? (
        <>
          <Header />
          <main
            className="max-w-7xl mx-auto pt-10 pb-12 px-4 lg:pb-16 lg:px-8"
            data-testid="mainContent"
          >
            {props.children}
          </main>
        </>
      ) : (
        <span data-testid="mainContent">{props.children}</span>
      )}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Layout;
