import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { redirectToPage } from 'helpers';

const Link = ({ href, name, onClick, className }) => {
  const handleClick = (e) => {
    e.preventDefault();

    if (onClick) onClick();
    else if (href) {
      redirectToPage(href);
    }
  };

  return (
    <a
      href={href}
      onClick={handleClick}
      className={classNames(className, 'kassa24-link-blue')}
    >
      {name}
    </a>
  );
};

Link.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Link.defaultProps = {
  onClick: null,
  className: '',
};

export default Link;
