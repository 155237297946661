import React from 'react';
import Skeleton from 'components/ui/Skeleton';

const LoadingContent = () => {
  return (
    <div
      className="border-2 rounded-lg border-gray-200 px-5 py-5"
      data-testid="loadingContent"
    >
      <div>
        <Skeleton />
        <Skeleton variant="rectangular" height={28} className="mt-5" />
      </div>
    </div>
  );
};

export default LoadingContent;
