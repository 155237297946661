import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = (props) => {
  const getClasses = () => {
    return classNames('kassa24-input-div', className, {
      flex: startAdornment,
      'kassa24-input-error': error,
    });
  };

  const {
    id,
    name,
    type,
    placeholder,
    value,
    startAdornment,
    className,
    onChange,
    error,
  } = props;

  return (
    <div className={getClasses()}>
      {startAdornment && (
        <div className="kassa24-icon-sm kassa24-icon">{startAdornment}</div>
      )}
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder || ''}
        value={value}
        className="kassa24-input"
        onChange={onChange}
      />
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  startAdornment: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

Input.defaultProps = {
  id: '',
  name: '',
  type: 'text',
  placeholder: '',
  value: '',
  startAdornment: null,
  className: '',
  error: false,
};

export default Input;
