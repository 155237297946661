import React, { useState, useMemo, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as yup from 'yup';
import useUser from 'hooks/useUser';

import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { LockClosedIcon } from '@heroicons/react/solid';
import { getClassNames, redirectToPage } from 'helpers';

const validationSchema = yup.object({
  userName: yup.string().required('Логин обязателен для заполнения'),
  password: yup.string().required('Пароль обязателен для заполнения'),
  captchaValue: yup.string().required('Капча обязательна для заполнения'),
});

const LoginForm = () => {
  const authBtnText = 'Авторизоваться';
  const { login, captcha, getCaptcha } = useUser();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(authBtnText);

  useEffect(() => {
    getCaptcha();
  }, []);

  const auth = ({ userName, password, captchaValue }, actions) => {
    setIsLoading(<div className="dot-spin"></div>);
    if (userName.trim() && password.trim() && captchaValue.trim()) {
      login({
        userName,
        password,
        capchaID: captcha.capchaID,
        capchaValue: captchaValue,
      })
        .then((res) => {
          if (res.success) {
            redirectToPage('main');
          }
          setIsLoading(authBtnText);
        })
        .catch(() => {
          setIsLoading(authBtnText);
        });
      actions.setSubmitting(false);
    } else {
      setIsLoading(authBtnText);
    }
  };

  const updateCaptcha = () => {
    getCaptcha();
  };

  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
      captchaValue: '',
      remember: true,
    },
    validationSchema: validationSchema,
    onSubmit: auth,
  });

  const FORM_FIELDS = useMemo(
    () => [
      {
        name: 'userName',
        label: `Логин`,
        type: 'text',
        autoComplete: 'userName',
      },
      {
        name: 'password',
        label: `Пароль`,
        type: 'password',
        autoComplete: 'current-password',
      },
      {
        name: 'captchaValue',
        label: 'Введите код с картинки',
        type: 'text',
        autoComplete: 'captchaValue',
      },
    ],
    []
  );

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className="mt-8 space-y-6"
      >
        <div className="rounded-md shadow-sm -space-y-px">
          {FORM_FIELDS.map(({ name, type, label, autoComplete }) => (
            <div key={name} className="relative">
              <input
                type={
                  type === 'password' && !showPassword ? 'password' : 'text'
                }
                autoComplete={autoComplete}
                placeholder={label}
                {...getFieldProps(name)}
                aria-invalid={Boolean(touched[name] && errors[name])}
                className={getClassNames(
                  Boolean(touched[name] && errors[name]) &&
                    'kassa24-input-error',
                  `kassa24-auth-input kassa24-input-${name}`
                )}
              />
              {name === 'captchaValue' && (
                <div onClick={updateCaptcha} className="cursor-pointer">
                  <img alt="captcha" src={captcha.capchaImage} />
                </div>
              )}
              {type === 'password' && (
                <button
                  onClick={handleShowPassword}
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center z-1"
                  data-testid="showPassBtn"
                >
                  {showPassword ? (
                    <EyeOffIcon
                      className="h-5 w-5 text-gray-300"
                      aria-hidden="true"
                      data-testid="showOffIcon"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-300"
                      aria-hidden="true"
                      data-testid="showIcon"
                    />
                  )}
                </button>
              )}
            </div>
          ))}
        </div>

        <div>
          <button type="submit" className="kassa24-button group">
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                className="h-5 w-5 text-kassa24-black"
                aria-hidden="true"
              />
            </span>
            {isLoading}
          </button>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
