import React, { useEffect, useState, useContext } from 'react';

import Link from 'components/ui/Link';
import {
  TERMINAL_NOCONNECTION_LINK,
  TERMINAL_NOPAY_LINK,
  TERMINAL_PROBLEMS_LINK,
} from 'constants/main';
import { getDate } from 'helpers';
import Table from 'components/ui/Table';
import AppContext from 'context/AppContext';
import ExpectedDateOfStatistic from 'components/ExpectedDateOfStatistic';

const PROBLEMATIC_ASO_TABLE_HEADER_CELLS = [
  { label: 'Проблемные АСО', field: 'problematicAso' },
  { label: getDate('date'), field: 'currentDate' },
  { label: '', field: '' },
];

const UNPROFITABLE_ASO_TABLE_HEADER_CELLS = [
  { label: 'Убыточные АСО', field: 'unprofitableAso' },
  { label: getDate('date'), field: 'currentDate' },
  { label: 'Прошлый месяц', field: 'previousMonth' },
  { label: 'План', field: 'plan' },
];

const defaultData = {
  comm: 0,
  commission: 0,
  dateFirst: '2022-01-02T00:00:00Z',
  dateReport: '2022-01-02T00:00:00Z',
  hourNopay: 0,
  hourNoping: 0,
  perf: 0,
  planLoss: 0,
  rentSum: 0,
  summa: 0,
  terminals: 0,
};

const percentColorSum = (a = 0) => (a < 0 ? '#e74c3c' : '#32be3f');
const percentColorCount = (a = 0) => (a > 0 ? '#e74c3c' : '#32be3f');

const generateProblematicAsoTableData = (
  data = { now: defaultData, old: defaultData }
) => {
  const colorNoping = ['', percentColorCount(data?.now?.hourNoping)];
  const colorNopay = ['', percentColorCount(data?.now?.hourNopay)];
  return [
    {
      problematicAso: (
        <Link
          href={TERMINAL_NOCONNECTION_LINK}
          name="Кол-во убыточных терминалов"
        />
      ),
      currentDate: data?.now?.hourNoping ?? 0,
      colors: colorNoping,
    },
    {
      problematicAso: (
        <Link
          href={TERMINAL_NOPAY_LINK}
          name="Кол-во терминалов без платежей (более суток)"
        />
      ),
      currentDate: data?.now?.hourNopay ?? 0,
      colors: colorNopay,
    },
  ];
};

const generateUnprofitableAsoTableData = (
  data = { now: defaultData, old: defaultData }
) => {
  const colorSum = [
    '',
    percentColorSum(data?.now?.summa),
    percentColorSum(data?.old?.summa),
  ];
  const colorCount = [
    '',
    percentColorCount(data?.now?.terminals),
    percentColorCount(data?.old?.terminals),
    '',
  ];
  return [
    {
      unprofitableAso: (
        <Link
          href={TERMINAL_PROBLEMS_LINK}
          name=" Кол-во убыточных терминалов"
        />
      ),
      currentDate: data?.now?.terminals ?? 0,
      previousMonth: data?.old?.terminals ?? 0,
      plan: data?.old?.planLoss ?? 0,
      colors: colorCount,
    },
    {
      unprofitableAso: (
        <Link href={TERMINAL_PROBLEMS_LINK} name=" Сумма убытка терминалов" />
      ),
      currentDate: data?.now?.summa ?? 0,
      previousMonth: data?.old?.summa ?? 0,
      plan: '',
      colors: colorSum,
    },
  ];
};

const AsoStatistic = () => {
  const [unprofitableAsotableData, setUnprofitableAsotableData] = useState(
    generateUnprofitableAsoTableData()
  );
  const [problematicAsotableData, setProblematicAsotableData] = useState(
    generateProblematicAsoTableData()
  );
  const { fetchAsoStatistics } = useContext(AppContext);

  useEffect(() => {
    fetchAsoStatistics().then(({ data: { data } }) => {
      setUnprofitableAsotableData(generateUnprofitableAsoTableData(data));
      setProblematicAsotableData(generateProblematicAsoTableData(data));
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        tableHead={UNPROFITABLE_ASO_TABLE_HEADER_CELLS}
        tableData={unprofitableAsotableData}
        tableSort={false}
      />
      <Table
        tableHead={PROBLEMATIC_ASO_TABLE_HEADER_CELLS}
        tableData={problematicAsotableData}
        tableSort={false}
      />
      <ExpectedDateOfStatistic />
    </>
  );
};

export default AsoStatistic;
