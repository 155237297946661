import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'components/ui/DatePicker';

import Button from 'components/ui/Button';

const SearchDateToolbar = ({
  clickHandler,
  btnName,
  handlerSetDateTo,
  handlerSetDateFrom,
}) => {
  const date = new Date();
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  useEffect(() => {
    date.setDate(1);
    setDateFrom(date);
    setDateTo(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetDateTo = (value) => {
    setDateTo(value);
    handlerSetDateTo(value);
  };
  const handleSetDateFrom = (value) => {
    setDateFrom(value);
    handlerSetDateFrom(value);
  };

  const clickCustomHandler = () => {
    clickHandler(dateFrom, dateTo);
  };

  return (
    <div className="grid lg:grid-cols-3 gap-5 ">
      <div className="pt-5 lg:pt-0 dateFrom">
        <DatePicker
          fullWidth
          selected={dateFrom}
          onChange={handleSetDateFrom}
          dateFormat="dd.MM.yyyy"
          maxDate={dateTo}
          data-testid="dateFrom"
        />
      </div>
      <div className="dateTo">
        <DatePicker
          selected={dateTo}
          onChange={handleSetDateTo}
          dateFormat="dd.MM.yyyy"
          maxDate={dateTo}
          data-testid="dateTo"
        />
      </div>
      <div className="dateBtn">
        <Button
          variant="contained"
          onClick={clickCustomHandler}
          style={{ height: 50 }}
        >
          {btnName}
        </Button>
      </div>
    </div>
  );
};

SearchDateToolbar.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  btnName: PropTypes.string.isRequired,
  handlerSetDateTo: PropTypes.func,
  handlerSetDateFrom: PropTypes.func,
};

SearchDateToolbar.defaultProps = {
  handlerSetDateTo: null,
  handlerSetDateFrom: null,
};

export default SearchDateToolbar;
