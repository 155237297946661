import React from 'react';
import PropTypes from 'prop-types';

import { useAlert } from 'hooks';
import AppContext from 'context/AppContext';
import WithAxios, { api } from 'components/WithAxios';

const AppProvider = (props) => {
  const { setSuccessMessage, setErrorMessage } = useAlert();

  const getCoins = () => {
    return api.post(`/terminals/count`).then((res) => {
      return [].concat(res.data);
    });
  };

  const getIdeasList = () => {
    return api.get(`/forum`).then((res) => {
      return res.data;
    });
  };

  const addNewIdea = (data) => {
    return api
      .post(`/forum/`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      })
      .then((res) => {
        if (!res.Status) {
          setSuccessMessage('Идея успешно добавлена');
        } else setErrorMessage('При добавлении идеи возникла ошибка');
        return res;
      });
  };

  const getIdeaDetail = (id) => {
    return api.get(`/forum/${id}`).then((res) => {
      return res;
    });
  };

  const setLike = (id, data) => {
    return api.post(`/forum/like/${id}`, data).then((res) => {
      //this is intentional, this function is like
    });
  };

  const setDislike = (id, data) => {
    return api.post(`/forum/dislike/${id}`, data).then((res) => {
      // this is intentional, this function is dislikes
    });
  };

  const addNewComment = (data, id) => {
    return api
      .post(`/forum/comment/${id}`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      })
      .then((res) => {
        if (!res.Status) {
          setSuccessMessage('Комментарий успешно добавлен');
        } else setErrorMessage('При добавлении комментария возникла ошибка');
        return res;
      });
  };

  const fetchEncashmentStatistics = () => {
    return api.get('/terminals/cash').then((res) => {
      return res;
    });
  };

  const fetchAsoStatistics = () => {
    return api.get('/terminals/problems').then((res) => {
      return res;
    });
  };

  const fetchFinancialStatistics = () => {
    return api.get('/terminals/turnover-detail').then((res) => {
      return res;
    });
  };

  const getCashDiagram = () => {
    return api.get('/terminals/cash-diagram').then((res) => {
      return res;
    });
  };

  const fetchTerminalsStatistics = () => {
    return api.get(`/terminals/count`).then((res) => {
      return res;
    });
  };

  const getMonitoring = () => {
    return api.get(`/monitoring`).then((res) => {
      return res;
    });
  };

  const getCashNight = () => {
    return api.get('/terminals/cash-night').then((res) => {
      return res;
    });
  };

  const getCashIdleDetail = () => {
    return api.get('/terminals/cash-idle-detail').then((res) => {
      return res;
    });
  };

  const getCashTechnik = () => {
    return api.get('/terminals/cash-technik').then((res) => {
      return res;
    });
  };

  const getNoConnection = () => {
    return api.get('/terminals/noconnection').then((res) => {
      return res;
    });
  };

  const getNoPay = () => {
    return api.get('/terminals/nopay').then((res) => {
      return res;
    });
  };

  const getTurnOverDetail = (data) => {
    return api.post('/terminals/turnover-detail-date', data).then((res) => {
      return res;
    });
  };

  const getProviderDetail = (data) => {
    return api.post('/terminals/provider-detail-date', data).then((res) => {
      return res;
    });
  };

  const getProblemsTerminal = () => {
    return api.get('/terminals/unprofit-detail').then((res) => {
      return res;
    });
  };

  const sendViewIdea = (id, data) => {
    return api.post(`/forum/view/${id}`, data).then((res) => {
      return res;
    });
  };

  const getDirectorProfiles = () => {
    return api.get('/profile').then((res) => {
      return res.data;
    });
  };

  const addNewProfile = (data) => {
    return api.post(`/profile`, data).then((res) => {
      if (res.data.success) setSuccessMessage('Профиль добавлен');
      else setErrorMessage('Возникла ошибка при добавлении профиля');
      return res.data;
    });
  };

  const updateBindingUser = (data) => {
    return api.put(`/profile`, data).then((res) => {
      if (res.data.success) setSuccessMessage('Профиль обновлен');
      else setErrorMessage('Возникла ошибка при обновлении профиля');
      return res.data;
    });
  };

  const deleteDirectorsProfile = (idUser) => {
    return api.delete(`/profile`, { data: { idUser } }).then((res) => {
      if (res.data.success) setSuccessMessage('Привязка к профилю удалена');
      else setErrorMessage('Возникла ошибка при удалении привязки профиля');
      return res.data;
    });
  };

  return (
    <AppContext.Provider
      value={{
        getCoins,
        addNewProfile,
        getIdeasList,
        setLike,
        setDislike,
        addNewIdea,
        addNewComment,
        fetchEncashmentStatistics,
        fetchAsoStatistics,
        getCashDiagram,
        fetchFinancialStatistics,
        fetchTerminalsStatistics,
        getIdeaDetail,
        getMonitoring,
        getCashNight,
        getCashIdleDetail,
        getCashTechnik,
        getNoConnection,
        getNoPay,
        getTurnOverDetail,
        getProviderDetail,
        sendViewIdea,
        getDirectorProfiles,
        updateBindingUser,
        deleteDirectorsProfile,
        getProblemsTerminal,
      }}
    >
      <WithAxios>{props.children}</WithAxios>
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AppProvider;
