export const MAIN_MENU = [
  { name: 'Аналитика', link: 'main' },
  { name: 'Наши идеи', link: 'ideas' },
  { name: 'Мониторинг', link: 'monitoring' },
];

export const TERMINAL_IDLE_LINK = 'terminals/idle';
export const TERMINAL_NIGHT_LINK = 'terminals/night';
export const TERMINAL_CASHTECHNIK_LINK = 'terminals/cashtechnik';
export const TERMINAL_PROBLEMS_LINK = 'terminals/problems';
export const TERMINAL_NOPAY_LINK = 'terminals/nopay';
export const TERMINAL_NOCONNECTION_LINK = 'terminals/noconnection';
export const TERMINAL_TURNOVER_LINK = 'terminals/turnover';
export const TERMINAL_PROVIFER_LINK = 'terminals/provider';

export const DRAWER_WIDTH = 235;
export const SITE_TITLE = 'Кабинет директора';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';

export const EXPECTED_DATE_OF_STATISTICS =
  '* Прогноз будет доступен после 7-го числа';

export const MONITORING_STATUSES = {
  coints: 'проблемы с монетоприемником',
  printer: 'проблемы с принтером',
  cpu: 'проблемы с процессором',
  hdd: 'проблемы с жестким диском',
  bils: 'проблемы с купюроприемником',
};
