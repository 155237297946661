import React from 'react';
import * as Sentry from '@sentry/react';
import {
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import history from 'appHistory';

import SignIn from 'pages/SignIn';
import Ideas from 'pages/Ideas';
import MainPage from 'pages/MainPage';
import Layout from 'components/Layout';
import Terminals from 'pages/Terminals';
import NotFoundPage from 'pages/NotFoundPage';
import Monitoring from 'pages/Monitoring';
import IdeaDetail from 'pages/Ideas/IdeaDetail';
import ChangePassword from 'components/ChangePassword';

const getRouterElement = (Component) => (
  <Layout>
    <Component />
  </Layout>
);

const Router = () => (
  <Sentry.ErrorBoundary fallback={NotFoundPage}>
    <HistoryRouter history={history}>
      <Routes>
        <Route exact path="/" element={getRouterElement(SignIn)} />
        <Route path="/main" element={getRouterElement(MainPage)} />
        <Route path="/ideas/:id" element={getRouterElement(IdeaDetail)} />
        <Route path="/ideas" element={getRouterElement(Ideas)} />
        <Route path="/monitoring" element={getRouterElement(Monitoring)} />
        <Route path="/terminals/:id" element={getRouterElement(Terminals)} />
        <Route
          path="/change-password"
          element={getRouterElement(ChangePassword)}
        />
        <Route path="*" exact element={getRouterElement(NotFoundPage)} />
      </Routes>
    </HistoryRouter>
  </Sentry.ErrorBoundary>
);

export default Router;
