import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';

import 'index.css';
import history from 'appHistory';
import { SENTRY_DSN } from 'constants/main';
import UiProvider from 'context/UiProvider';
import { getFromLocalStorage } from 'helpers';
import AppProvider from 'context/AppProvider';
import UserProvider from 'context/UserProvider';

import Routes from './router';

if (!window.location.href.includes('localhost')) {
  Sentry.init({
    normalizeDepth: 10,
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  });

  Sentry.configureScope((scope) => {
    const userInfo = getFromLocalStorage('userInfo');

    if (userInfo) {
      const { userId, userLogin, userType, userName } = userInfo;

      scope.setUser({
        userId,
        userLogin,
        userType,
        userName,
      });
    }
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <UiProvider>
        <AppProvider>
          <UserProvider>
            <Routes />
          </UserProvider>
        </AppProvider>
      </UiProvider>
    </React.StrictMode>
  </HelmetProvider>
);
