import React, { useContext, useEffect, useState } from 'react';

import { SITE_TITLE, MONITORING_STATUSES } from 'constants/main';
import Table from 'components/ui/Table';
import SearchToolbar from 'components/ui/SearchToolbar';
import {
  getDate,
  documentSetTitle,
  exportToXlsx,
  getValueForSearch,
} from 'helpers';
import AppContext from 'context/AppContext';
import TerminalContent from 'components/TerminalContent';
import UiContext from 'context/UiContext';

const PAGE_TITLE = 'Мониторинг терминалов';
const TABLE_HEADER = [
  { label: 'Терминал', field: 'idTerminal' },
  { label: 'Денег', field: 'money' },
  { label: 'Купюр', field: 'bills' },
  { label: 'Монет', field: 'coins' },
  { label: 'Последний платеж', field: 'lastPayment' },
  { label: 'Последний сигнал', field: 'lastSignal' },
  { label: 'Статус', field: 'status' },
  { label: 'Монетник', field: 'coiner' },
  { label: 'Принтер', field: 'printer' },
];

const Monitoring = () => {
  const date = getDate('date');
  const { getMonitoring } = useContext(AppContext);
  const { setBreadcrumbs } = useContext(UiContext);
  const [tableData, setTableData] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    setBreadcrumbs([{ name: PAGE_TITLE }]);

    const newData = [];
    getMonitoring().then((res) => {
      if (res.data) {
        res.data.forEach((item) => {
          let itemData = {};
          TABLE_HEADER.forEach(({ field }) => {
            itemData[field] = getTableFieldValue(field, item[field]);
          });

          newData.push(itemData);
        });
        setTableFilterData(newData);
        setTableData(newData);
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (search) => {
    const formatedSearch = search.toLowerCase();
    if (search !== '') {
      const filteredItems = tableData.filter(
        (item) =>
          getValueForSearch(item.idTerminal).indexOf(formatedSearch) !== -1
      );
      setTableFilterData(filteredItems);
    } else setTableFilterData(tableData);
  };

  const handleDownload = () => {
    const cols = [
      { width: '10' },
      { width: '10' },
      { width: '10' },
      { width: '10' },
      { width: '15' },
      { width: '15' },
      { width: '10' },
      { width: '10' },
      { width: '10' },
    ];
    exportToXlsx(cols, 'resTable', PAGE_TITLE + ' на ' + date);
  };

  const getTableFieldValue = (field, value) => {
    if (field === 'status' && value) {
      const statusEnList = value.split(',');
      const statusRuList = [];
      statusEnList.forEach((val) => {
        statusRuList.push(MONITORING_STATUSES[val]);
      });
      return statusRuList.join('\r\n');
    }
    return value;
  };

  const getContent = () => (
    <>
      <SearchToolbar
        handleSearch={handleSearch}
        handleDownload={handleDownload}
        label="Поиск по терминалам"
      />
      <div className="shadow-lg rounded-lg pb-1  ">
        <div id="resTable">
          <Table
            tableHead={TABLE_HEADER}
            tableData={tableFilterData}
            showPaging={true}
            tableSort={true}
            pageSize={30}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      <h1 className="kassa24-h1">
        {PAGE_TITLE} на {date}
      </h1>

      <TerminalContent
        content={getContent()}
        tableData={tableData}
        isLoading={isLoading}
      />
    </>
  );
};

export default Monitoring;
