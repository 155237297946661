import React from 'react';

import LoginForm from 'components/LoginForm';
import Snackbar from 'components/ui/Snackbar';

const SignIn = () => {
  return (
    <div className="max-w-screen-2xl mx-auto px-3 h-full">
      <Snackbar />
      <div className="kassa24-signin-grid">
        <div className="kassa24-signin-left-column">
          <div className="flex h-full w-full items-center">
            <div className="self-center m-auto">
              <img
                src="/static/images/icon_kassa24.svg"
                alt="kassa24-logo"
                className="mx-auto max-w-xs"
              />
              <div className="hidden lg:block">
                <h3 className="text-4xl my-5 font-bold">Кабинет директора</h3>
                <img
                  src="/static/images/login_img.png"
                  alt="login"
                  width="300"
                  className="mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 self-center">
          <div className="max-w-xl mx-auto">
            <div className="mb-5 text-center ">
              <h3 className="text-3xl font-bold">Добро пожаловать</h3>
              <p className="mt-2 text-center text-sm text-gray-600">
                Введите данные авторизации ниже
              </p>
            </div>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
