import React from 'react';
import { motion } from 'framer-motion';

import { redirectToPage } from 'helpers';
import Button from 'components/ui/Button';
import Logo from 'assets/img/kassa24-white.svg';

const ErrorPage = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div>
        <div className="max-w-xl mx-auto text-center">
          <motion.div>
            <img src={Logo} className="mx-auto max-h-60" alt="notFound" />
          </motion.div>
          <div className="text-md uppercase text-sky-600 pt-6 lg:pt-16">
            404 error
          </div>
          <motion.div>
            <h3 className="text-4xl font-bold ">
              Извините, страница не найдена!
            </h3>
          </motion.div>
          <p className="text-sm py-3 text-gray-500">
            К сожалению, мы не смогли найти нужную страницу. Возможно вы
            неправильно ввели URL?
          </p>
          <Button
            onClick={() => redirectToPage('main')}
            className="max-w-sm mx-auto"
          >
            На главную
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
