import React from 'react';
import { Disclosure } from '@headlessui/react';
import PropTypes from 'prop-types';

import { MAIN_MENU } from 'constants/main';
import { redirectToPage } from 'helpers';

const HeadMobileMenu = ({ open }) => {
  return (
    <>
      <Disclosure.Panel className="lg:hidden" static={open}>
        <div className="pt-2 pb-3 px-2 space-y-1">
          {MAIN_MENU.map((item) => (
            <a
              key={item.name}
              href={item.link}
              onClick={() => redirectToPage(item.link)}
              className="block rounded-md py-2 px-3 text-base font-medium text-white hover:text-white "
            >
              {item.name}
            </a>
          ))}
        </div>
      </Disclosure.Panel>
    </>
  );
};

HeadMobileMenu.propTypes = {
  open: PropTypes.bool,
};

HeadMobileMenu.defaultProps = {
  open: false,
};
export default HeadMobileMenu;
