import React, { useContext, useEffect, useState } from 'react';

import SearchToolbar from 'components/ui/SearchToolbar';
import Table from 'components/ui/Table';
import { SITE_TITLE } from 'constants/main';
import {
  documentSetTitle,
  getDate,
  getPriceFormat,
  exportToXlsx,
  getValueForSearch,
  getTableFieldValue,
} from 'helpers';
import AppContext from 'context/AppContext';
import StatisticDetailsBackButton from 'components/StatisticDetailsBackButton';
import TerminalContent from 'components/TerminalContent';
import UiContext from 'context/UiContext';

const PAGE_TITLE = 'Давно не инкассированные терминалы';
const TABLE_HEADER = [
  { label: 'Номер терминала', field: 'IDTerminal' },
  { label: 'Название терминала', field: 'TerminalName' },
  { label: 'Адрес терминала', field: 'Address' },
  { label: 'Сумма', field: 'SumOld' },
  { label: 'Время последней икассации', field: 'MaxDate' },
  { label: 'Техник', field: 'FullName' },
];

const Idle = () => {
  const date = getDate('date');
  const { getCashIdleDetail } = useContext(AppContext);
  const [tableData, setTableData] = useState([]);
  const [tableFooter, setTableFooter] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setBreadcrumbs } = useContext(UiContext);

  useEffect(() => {
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    setBreadcrumbs([{ name: PAGE_TITLE }]);

    const newData = [];
    const footer = [];

    getCashIdleDetail().then((res) => {
      let summ = 0;
      if (res?.data) {
        res.data.forEach((item) => {
          let itemData = {};
          TABLE_HEADER.forEach(({ field }) => {
            itemData[field] = getTableFieldValue(field, item[field]);
            if (field === 'SumOld') summ += item[field];
          });

          newData.push(itemData);
        });

        footer.push({
          IDTerminal: '',
          TerminalName: <b>{'записей: ' + res.data.length}</b>,
          Address: '',
          SumOld: <b>{getPriceFormat(summ)}</b>,
          MaxDate: '',
          FullName: '',
        });
        setTableFooter(footer);
        setTableFilterData(newData);
        setTableData(newData);
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (search) => {
    const formatedSearch = search.toLowerCase();
    if (search !== '') {
      const filteredItems = tableData.filter(
        (item) =>
          getValueForSearch(item.IDTerminal).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.TerminalName).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.Address).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.SumOld).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.FullName).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.MaxDate).indexOf(formatedSearch) !== -1
      );
      setTableFilterData(filteredItems);
    } else setTableFilterData(tableData);
  };

  const handleDownload = () => {
    const cols = [
      { width: '10' },
      { width: '10' },
      { width: '20' },
      { width: '20' },
      { width: '20' },
      { width: '20' },
    ];
    exportToXlsx(cols, 'resTable', PAGE_TITLE + ' на ' + date);
  };

  const getContent = () => (
    <>
      <SearchToolbar
        handleDownload={handleDownload}
        handleSearch={handleSearch}
      />
      <div id="resTable" style={{ display: 'none' }}>
        <Table
          tableHead={TABLE_HEADER}
          tableData={tableFilterData}
          showPaging={false}
        />
      </div>

      <Table
        tableHead={TABLE_HEADER}
        tableData={tableFilterData}
        showPaging={true}
        tableSort={true}
        pageSize={30}
        tableFooter={tableFooter}
      />
    </>
  );

  return (
    <>
      <StatisticDetailsBackButton title={`${PAGE_TITLE} на ${date}`} />
      <div>
        <TerminalContent
          content={getContent()}
          isLoading={isLoading}
          tableData={tableData}
        />
      </div>
    </>
  );
};

export default Idle;
