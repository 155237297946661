import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchIcon } from '@heroicons/react/outline';
import { DocumentDownloadIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import Button from 'components/ui/Button';
import Input from 'components/ui/Input';

const SearchToolbar = ({ handleSearch, handleDownload, border, label }) => {
  const [filterName, setFilterName] = useState('');

  const handleFilterByName = (event) => {
    const search = event.target.value;
    setFilterName(search);
    handleSearch(search);
  };

  return (
    <div
      className={classNames('flex justify-between py-5 items-center ', {
        'kassa24-search-border pr-3': border,
      })}
    >
      <Input
        value={filterName}
        onChange={handleFilterByName}
        placeholder={label}
        className="max-w-xs"
        startAdornment={<SearchIcon className="text-gray-500" />}
      />

      <Button
        onClick={handleDownload}
        size="small"
        variant="icon"
        title="Скачать"
      >
        <DocumentDownloadIcon className="kassa24-link-blue kassa24-icon-md" />
      </Button>
    </div>
  );
};

SearchToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  border: PropTypes.bool,
  label: PropTypes.string,
};

SearchToolbar.defaultProps = {
  border: false,
  label: 'Поиск',
};

export default SearchToolbar;
