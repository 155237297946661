import React from 'react';
import PropTypes from 'prop-types';

import NoFound from 'components/NoFound';
import LoadingContent from 'components/LoadingContent';

const TerminalContent = ({ isLoading, tableData, content, loadingContent }) => {
  const getContent = () => {
    if (isLoading) {
      return loadingContent;
    } else {
      if (tableData.length > 0) {
        return content;
      } else {
        return <NoFound />;
      }
    }
  };

  return getContent();
};

TerminalContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tableData: PropTypes.array.isRequired,
  content: PropTypes.object.isRequired,
  loadingContent: PropTypes.object,
};

TerminalContent.defaultProps = {
  loadingContent: <LoadingContent />,
};

export default TerminalContent;
