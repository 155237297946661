import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';

const CustomDatePicker = ({ selected, onChange, dateFormat, ...other }) => {
  useEffect(() => {
    registerLocale('ru', ru);
  }, []);

  return (
    <div className="kassa24-datepicker">
      <DatePicker
        selected={selected}
        onChange={onChange}
        dateFormat={dateFormat}
        locale={ru}
        calendarClassName="kassa24-calendar"
        {...other}
      />
    </div>
  );
};

CustomDatePicker.propTypes = {
  selected: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  dateFormat: 'dd MMMM yyyy., HH:mm',
};

export default CustomDatePicker;
