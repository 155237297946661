import React, { useEffect, useState, useContext } from 'react';

import { useAlert } from 'hooks';
import Table from 'components/ui/Table';
import { SITE_TITLE } from 'constants/main';
import {
  documentSetTitle,
  getDate,
  exportToXlsx,
  getValueForSearch,
} from 'helpers';
import AppContext from 'context/AppContext';
import SearchToolbar from 'components/ui/SearchToolbar';
import SearchDateToolbar from 'components/ui/SearchDateToolbar';
import StatisticDetailsBackButton from 'components/StatisticDetailsBackButton';
import TerminalContent from 'components/TerminalContent';
import UiContext from 'context/UiContext';

const PAGE_TITLE = 'Детализация оборота по провайдерам';
const TABLE_HEADER = [
  { label: 'Провайдер', field: 'IDAgents' },
  { label: 'Оборот', field: 'Amount' },
  { label: 'Доход', field: 'Remuneration' },
  { label: 'Доход без учета аренды', field: 'Efficiency' },
];

const Provider = () => {
  const date = getDate('date');
  const [dateTo, setDateTo] = useState(date);
  const [dateFrom, setDateFrom] = useState('01.' + getDate('myear'));
  const { setBreadcrumbs } = useContext(UiContext);

  const { getProviderDetail } = useContext(AppContext);
  const [tableData, setTableData] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setErrorMessage } = useAlert();

  useEffect(() => {
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    handlerGetStatProvider();
    setBreadcrumbs([{ name: PAGE_TITLE }]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerSetDateFrom = (dateFiltTo) => {
    setDateFrom(getDate('date', dateFiltTo, '', true));
  };

  const handlerSetDateTo = (dateFiltFrom) => {
    setDateTo(getDate('date', dateFiltFrom, '', true));
  };

  const getReportHandler = () => {
    handlerGetStatProvider();
  };

  const handlerGetStatProvider = () => {
    const newData = [];
    setIsLoading(true);

    getProviderDetail({
      firstdata: getDate('date', dateFrom, 'yyyy-mm-dd'),
      lastdata: getDate('date', dateTo, 'yyyy-mm-dd'),
    }).then((res) => {
      if (!res?.success && res?.data) {
        res.data.forEach((item) => {
          let itemData = {
            IDAgents: getValue('IDAgents', item.IDAgents),
            Amount: getValue('Amount', item.Amount),
            Remuneration: getValue('Remuneration', item.Remuneration),
            Efficiency: getValue('Efficiency', item.Efficiency),
          };

          newData.push(itemData);
        });
        setTableFilterData(newData);
        setTableData(newData);
      }
      setIsLoading(false);
    });
  };

  const getValue = (_, value) => {
    return value;
  };

  const handleSearch = (search) => {
    const formatedSearch = search.toLowerCase();
    if (search !== '') {
      const filteredItems = tableData.filter(
        (item) =>
          getValueForSearch(item.IDAgents).indexOf(formatedSearch) !== -1
      );
      setTableFilterData(filteredItems);
    } else setTableFilterData(tableData);
  };

  const handleDownload = () => {
    if (tableFilterData.length > 0) {
      const cols = [];
      for (let i = 0; i < 4; i++) cols.push({ width: '25' });
      exportToXlsx(cols, 'resTable', PAGE_TITLE + ' ' + dateTo);
    } else setErrorMessage('Нет данных для выгрузки');
  };

  const getContent = () => (
    <>
      <div id="resTable" style={{ display: 'none' }}>
        <Table
          tableHead={TABLE_HEADER}
          tableData={tableFilterData}
          showPaging={false}
        />
      </div>

      <Table
        tableHead={TABLE_HEADER}
        tableData={tableFilterData}
        showPaging={true}
        tableSort={true}
        pageSize={30}
      />
    </>
  );

  return (
    <>
      <StatisticDetailsBackButton
        title={`${PAGE_TITLE} с ${dateFrom} по ${dateTo}`}
      />

      <div>
        <div className="lg:grid grid-cols-2 items-center">
          <div className="col-span-1">
            <SearchDateToolbar
              clickHandler={getReportHandler}
              btnName="Сформировать"
              handlerSetDateTo={handlerSetDateTo}
              handlerSetDateFrom={handlerSetDateFrom}
            />
          </div>
          <div className="col-span-1">
            <SearchToolbar
              handleSearch={handleSearch}
              handleDownload={handleDownload}
              border={false}
            />
          </div>
        </div>

        <TerminalContent
          content={getContent()}
          isLoading={isLoading}
          tableData={tableData}
        />
      </div>
    </>
  );
};

export default Provider;
