import React, { useEffect, useState, useContext } from 'react';

import SearchToolbar from 'components/ui/SearchToolbar';
import SearchDateToolbar from 'components/ui/SearchDateToolbar';
import Table from 'components/ui/Table';
import { SITE_TITLE } from 'constants/main';
import {
  documentSetTitle,
  getDate,
  getValueForSearch,
  exportToXlsx,
} from 'helpers';
import AppContext from 'context/AppContext';
import { useAlert } from 'hooks';
import StatisticDetailsBackButton from 'components/StatisticDetailsBackButton';
import TerminalContent from 'components/TerminalContent';
import UiContext from 'context/UiContext';

const PAGE_TITLE = 'Детализация оборота по терминалам ';

const TABLE_HEADER = [
  { label: 'Номер терминала', field: 'IDTerminal' },
  { label: 'Терминал', field: 'Terminal' },
  { label: 'Оборот', field: 'TurnAmount' },
  { label: 'Сумма аренды', field: 'RentSum' },
  { label: 'Доход', field: 'Efficiency' },
  { label: 'Кол-во проработанных дней', field: 'CntWorkDay' },
  { label: 'Кол-во платежей', field: 'CountPay' },
  { label: 'Прогноз дохода', field: 'Remuneration' },
  { label: 'Регион', field: 'Region' },
  { label: 'Техник', field: 'Tech' },
];

const TurnOver = () => {
  const date = getDate('date');
  const [dateTo, setDateTo] = useState(date);
  const [dateFrom, setDateFrom] = useState('01.' + getDate('myear'));
  const { getTurnOverDetail } = useContext(AppContext);
  const [tableData, setTableData] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setErrorMessage } = useAlert();
  const { setBreadcrumbs } = useContext(UiContext);

  useEffect(() => {
    documentSetTitle(SITE_TITLE + ': ' + PAGE_TITLE);
    setBreadcrumbs([{ name: PAGE_TITLE }]);
    handlerfetchFinancialStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerSetDateFrom = (dateFiltTo) => {
    setDateFrom(getDate('date', dateFiltTo, '', true));
  };

  const handlerSetDateTo = (dateFiltFrom) => {
    setDateTo(getDate('date', dateFiltFrom, '', true));
  };

  const getReportHandler = () => {
    handlerfetchFinancialStatistics();
  };

  const handlerfetchFinancialStatistics = () => {
    setIsLoading(true);
    const newData = [];
    getTurnOverDetail({
      firstdata: getDate('date', dateFrom, 'yyyy-mm-dd'),
      lastdata: getDate('date', dateTo, 'yyyy-mm-dd'),
    }).then((res) => {
      if (!res?.success && res?.data?.Terminals) {
        res.data.Terminals.forEach((item) => {
          let itemData = {
            IDTerminal: item.IDTerminal,
            Terminal: item.Terminal,
            TurnAmount: item.TurnAmount,
            RentSum: item.RentSum,
            Efficiency: item.Efficiency,
            CntWorkDay: item.CntWorkDay,
            CountPay: item.CountPay,
            Remuneration: item.Remuneration,
            Region: item.Region,
            Tech: item.Tech,
          };

          newData.push(itemData);
        });
        setTableFilterData(newData);
        setTableData(newData);
      }
      setIsLoading(false);
    });
  };

  const handleSearch = (search) => {
    const formatedSearch = search.toLowerCase();
    if (search !== '') {
      const filteredItems = tableData.filter(
        (item) =>
          getValueForSearch(item.IDTerminal).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.Terminal).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.Tech).indexOf(formatedSearch) !== -1 ||
          getValueForSearch(item.Region).indexOf(formatedSearch) !== -1
      );
      setTableFilterData(filteredItems);
    } else setTableFilterData(tableData);
  };

  const handleDownload = () => {
    if (tableFilterData.length > 0) {
      const cols = [];
      for (let i = 0; i < 10; i++) cols.push({ width: '10' });
      exportToXlsx(cols, 'resTable', PAGE_TITLE + ' ' + date);
    } else setErrorMessage('Нет данных для выгрузки');
  };

  const getContent = () => (
    <>
      <div id="resTable" style={{ display: 'none' }}>
        <Table
          tableHead={TABLE_HEADER}
          tableData={tableFilterData}
          showPaging={false}
        />
      </div>

      <Table
        tableHead={TABLE_HEADER}
        tableData={tableFilterData}
        showPaging={true}
        tableSort={true}
        pageSize={30}
      />
    </>
  );

  return (
    <>
      <StatisticDetailsBackButton
        title={`${PAGE_TITLE} с ${dateFrom} по ${dateTo}`}
      />

      <div>
        <div className="lg:grid grid-cols-2 items-center">
          <div className="col-span-1">
            <SearchDateToolbar
              clickHandler={getReportHandler}
              btnName="Сформировать"
              handlerSetDateTo={handlerSetDateTo}
              handlerSetDateFrom={handlerSetDateFrom}
            />
          </div>
          <div className="col-span-1">
            <SearchToolbar
              handleSearch={handleSearch}
              handleDownload={handleDownload}
              border={false}
            />
          </div>
        </div>

        <TerminalContent
          content={getContent()}
          isLoading={isLoading}
          tableData={tableData}
        />
      </div>
    </>
  );
};

export default TurnOver;
