import React from 'react';
import PropTypes from 'prop-types';
import Select from 'rc-select';
import Pagination from 'rc-pagination';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

import localeInfo from './src/locale/ru_RU';

const PaginationCustom = ({
  pageSize,
  total,
  onPageChange,
  onShowSizeChange,
  pageSizeOptions,
  labelDisplayedRows,
  showSizeChanger,
  hideOnSinglePage,
}) => {
  const handleChangePage = (pageNum) => {
    onPageChange(pageNum);
  };

  const handleOnShowSizeChange = (_, checkedValue) => {
    const e = {};
    e.target = {};
    e.target.value = checkedValue;
    onShowSizeChange(e);
  };

  return (
    <>
      <div className="text-right border-t border-gray-200 px-6 py-3">
        <div className="relative flex justify-end">
          <Pagination
            selectComponentClass={Select}
            total={total}
            showTotal={labelDisplayedRows}
            showTitle={false}
            pageSize={pageSize}
            showSizeChanger={showSizeChanger}
            defaultPageSize={pageSize}
            hideOnSinglePage={hideOnSinglePage}
            onChange={handleChangePage}
            onShowSizeChange={handleOnShowSizeChange}
            pageSizeOptions={pageSizeOptions}
            showPrevNextJumpers={true}
            prevIcon={<ChevronLeftIcon className="text-gray-800 " />}
            nextIcon={<ChevronRightIcon className="text-gray-800" />}
            locale={localeInfo}
          />
        </div>
      </div>
    </>
  );
};

PaginationCustom.propTypes = {
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  pageSizeOptions: PropTypes.array,
  labelDisplayedRows: PropTypes.func,
  showSizeChanger: PropTypes.bool,
  hideOnSinglePage: PropTypes.bool,
};

PaginationCustom.defaultProps = {
  onPageChange: null,
  onShowSizeChange: null,
  pageSizeOptions: [],
  labelDisplayedRows: null,
  showSizeChanger: false,
  hideOnSinglePage: false,
};

export default PaginationCustom;
