import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import UserReducer from 'reducers/UserReducer';
import UserContext from 'context/UserContext';
import { getFromLocalStorage, setToLocalStorage } from 'helpers';
import WithAxios, { api } from 'components/WithAxios';
import { useAlert } from 'hooks';
import { SET_CAPTCHA } from 'constants/actionTypes';

const USER_INFO = getFromLocalStorage('userInfo');
const initialState = {
  userInfo: USER_INFO ? USER_INFO : {},
  captcha: {},
};

const UserProvider = (props) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);
  const { setSuccessMessage, setErrorMessage } = useAlert();

  const getCaptcha = () => {
    return api.get('/capcha').then((res) => {
      if (res.data.success) {
        dispatch({ type: SET_CAPTCHA, captcha: res.data.data });
      } else setErrorMessage('Ошибка получения капчи');
    });
  };

  const login = (params) => {
    return api
      .post('/login/token', params)
      .then((res) => {
        if (res.data.success) {
          const result = res.data.data;
          setToLocalStorage('userInfo', result);
          dispatch({ type: SET_CAPTCHA, captcha: {} });
          return getUserData(res.data.data).then((resD) => {
            result.id = resD.id;
            result.other = resD;
            setUserFields(result);
            setToLocalStorage('userInfo', result);
            setSuccessMessage('Вы успешно авторизовались');
            return res.data;
          });
        } else {
          setErrorMessage('Неверный логин или пароль');
        }
      })
      .catch(() => {
        getCaptcha();
      });
  };

  const changePassword = (username, oldPassword, newPassword) => {
    return api
      .post('/login/change-password', {
        username,
        oldPassword,
        newPassword,
      })
      .then((res) => {
        if (res.data.success) {
          setSuccessMessage('Пароль успешно изменен');
          return res.data;
        } else setErrorMessage('Не удалось изменить пароль');
      });
  };

  const saveUser = (data) => {
    const userData = getFromLocalStorage('userInfo');
    return api.post('/profile/edit', data).then((res) => {
      if (res.data.success) {
        getUserData(userData);
        setSuccessMessage('Данные успешно сохранены');
      } else setErrorMessage('Ошибка при обновлении профиля');
      return res.data;
    });
  };

  const addNewProfile = (username, password) => {
    return api
      .post('/profile/edit', { login: username, password })
      .then((res) => {
        if (res.data.success) {
          setSuccessMessage('Профиль успешно добавлен');
        } else setErrorMessage('Ошибка при добавлении профиля');
        return res.data;
      });
  };

  const getUserData = (result) => {
    return api
      .post('/forum/balance', {
        directorId: Number(result.userId),
        login: result.userLogin,
        fullname: result.userName,
      })
      .then((res) => {
        return res.data;
      });
  };

  const setUserFields = (result) => {
    dispatch({
      type: 'SET_USER_FIELDS',
      payload: { userInfo: result },
    });
  };

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
        login,
        saveUser,
        getUserData,
        addNewProfile,
        setUserFields,
        changePassword,
        getCaptcha,
      }}
    >
      <WithAxios>{props.children}</WithAxios>
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default UserProvider;
