import { SET_USER_FIELDS, SET_CAPTCHA } from 'constants/actionTypes';

const UserReducer = (state, action) => {
  if (action) {
    if (action.type === SET_USER_FIELDS) {
      return {
        ...state,
        ...action.payload,
      };
    } else if (action.type === SET_CAPTCHA) {
      return {
        ...state,
        captcha: action.captcha,
      };
    }
    return state;
  } else return state;
};

export default UserReducer;
