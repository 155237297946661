import { useMemo } from 'react';
import axios from 'axios';

import { API_URL } from 'constants/api';
import { useAlert } from 'hooks';
import {
  makeInterceptorRequestUseConfig,
  logErrorToSentry,
  checkErrorMessage,
} from 'helpers';

export const api = axios.create({
  baseURL: API_URL,
});

const WithAxios = ({ children }) => {
  const { setErrorMessage } = useAlert();

  useMemo(() => {
    api.interceptors.request.use((config) => {
      return makeInterceptorRequestUseConfig(config);
    });

    api.interceptors.response.use(
      (res) => res,
      (err) => {
        if (typeof err.response !== 'undefined') {
          const { status } = err.response;

          const errorMessage = checkErrorMessage(err.response.data);

          if (errorMessage) {
            setErrorMessage(errorMessage);
          }

          if (status >= 500) {
            logErrorToSentry(err);
          }
        }

        return Promise.reject(err);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default WithAxios;
