import React, { useState, useCallback, useEffect, useContext } from 'react';
import * as yup from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { CheckCircleIcon } from '@heroicons/react/outline';

import useUser from 'hooks/useUser';
import {
  hasUpperCaseSymbol,
  hasLowerCaseSymbol,
  hasNumberSymbol,
  hasSpecialSymbol,
} from 'helpers';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import UiContext from 'context/UiContext';

const CHECK_PASSWORD_CONDITIONS = [
  {
    label: ' Пароль должен содержать не менее 8 символов',
    name: 'textlength',
  },
  {
    label: 'Пароль должен содержать хотя бы 1 прописную букву',
    name: 'uppercase',
  },
  {
    label: 'Пароль должен содержать хотя бы 1 строчную букву',
    name: 'lowercase',
  },
  {
    label: 'Пароль должен содержать хотя бы 1 цифру',
    name: 'number',
  },
  {
    label: 'Пароль должен содержать хотя бы 1 символ',
    name: 'specialCharacter',
  },
];

const formFields = [
  {
    name: 'oldPassword',
    label: 'Старый пароль',
    type: 'password',
  },
  {
    name: 'newPassword',
    label: 'Новый пароль',
    type: 'password',
  },
  {
    name: 'confirmPassword',
    label: 'Подтвердите пароль',
    type: 'password',
  },
];

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('Обязателен для заполнения'),
  newPassword: yup
    .string()
    .required('Обязателен для заполнения')
    .min(8, 'Минимальное кол-во символов 8')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Пароль не соответствует требованиям'
    ),
  confirmPassword: yup
    .string()
    .required('Обязателен для заполнения')
    .oneOf([yup.ref('newPassword')], 'Пароли не совпадают'),
});

const ChangePassword = () => {
  const { changePassword } = useUser();
  const [changePassErr, setChangePassErr] = useState(false);
  const { userInfo } = useUser();
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [passErrors, setPassError] = useState({
    textlength: false,
    uppercase: false,
    number: false,
    lowercase: false,
    specialCharacter: false,
    thePasswordsMatch: false,
  });
  const { setBreadcrumbs } = useContext(UiContext);

  useEffect(() => {
    checkPassword(newPass);
    setBreadcrumbs([{ name: 'Смена пароля' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPass]);

  const handleOnChangePassword = (e) => {
    if (e.target.name === 'newPassword') {
      setNewPass(e.target.value);
    } else if (e.target.name === 'confirmPassword') {
      setConfirmPass(e.target.value);
    }
  };

  const handleChangePassword = ({ oldPassword, newPassword }) => {
    changePassword(userInfo.userLogin, oldPassword, newPassword).then((res) => {
      if (res?.success) {
        setChangePassErr(false);
      } else {
        setChangePassErr(true);
      }
    });
  };

  const checkPassword = useCallback(
    (password) => {
      let tempPassError = {
        ...passErrors,
      };

      if (hasNumberSymbol(password)) {
        tempPassError = {
          ...tempPassError,
          number: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          number: false,
        };
      }

      if (hasLowerCaseSymbol(password)) {
        tempPassError = {
          ...tempPassError,
          lowercase: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          lowercase: false,
        };
      }

      if (hasUpperCaseSymbol(password)) {
        tempPassError = {
          ...tempPassError,
          uppercase: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          uppercase: false,
        };
      }

      if (password.length >= 8) {
        tempPassError = {
          ...tempPassError,
          textlength: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          textlength: false,
        };
      }

      if (hasSpecialSymbol(password)) {
        tempPassError = {
          ...tempPassError,
          specialCharacter: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          specialCharacter: false,
        };
      }

      if (newPass === confirmPass && newPass) {
        tempPassError = {
          ...tempPassError,
          thePasswordsMatch: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          thePasswordsMatch: false,
        };
      }

      setPassError(tempPassError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [passErrors, newPass]
  );

  const getValidateStatus = (name) =>
    passErrors[name] ? (
      <CheckCircleIcon className="w-5 h-5" />
    ) : (
      <div className="circleOutline" />
    );

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleChangePassword,
  });

  const { errors, touched, handleSubmit, values } = formik;

  return (
    <div>
      <h1 className="kassa24-h1">Смена пароля</h1>
      <div className="lg:grid grid-cols-2 gap-5">
        <div className="col-span-1 rounded-lg shadow-lg px-5 py-5">
          <p className="mb-2">Введите пароли</p>
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <div>
                {formFields.map(({ name, label, autoComplete, type }, key) => {
                  return (
                    <div key={key} className="mb-3">
                      <Input
                        id={name}
                        autoComplete={autoComplete}
                        key={name}
                        placeholder={label}
                        type={type}
                        name={name}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleOnChangePassword(e);
                        }}
                        value={values[name]}
                        error={Boolean(touched[name] && errors[name])}
                      />
                    </div>
                  );
                })}
                {changePassErr && (
                  <div className="kassa24-error">
                    Не удалось изменить пароль
                  </div>
                )}
                <Button type="submit">Изменить</Button>
              </div>
            </Form>
          </FormikProvider>
        </div>
        <div className="mt-3 lg:mt-0 rounded-lg shadow-lg px-5 py-5">
          <div>
            <p className="mb-2">Требования к паролю</p>
            {CHECK_PASSWORD_CONDITIONS.map((item, key) => (
              <p
                key={key}
                className={`${
                  passErrors[item.name] && 'kassa24-success-text'
                } ${'kassa24-pass-rule'}`}
              >
                {getValidateStatus(item.name)} {item.label}
              </p>
            ))}
            <p className="text-gray-500">Например: Qwerty123$</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
