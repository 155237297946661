import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import Editor from 'components/ui/Editor';
import AppContext from 'context/AppContext';
import { useUser } from 'hooks';

const FIELDS = [{ name: 'text', type: 'textarea', label: 'Текст' }];

const ERR_FIELD_TEXT = 'Поле обязательно для заполнения';

const AddComment = ({ handleCloseModal, id, handleAfterAddComment }) => {
  const [editedText, setEditedText] = useState('');
  const [err, setError] = useState(false);
  const { addNewComment } = useContext(AppContext);
  const { userInfo } = useUser();

  const sendComment = () => {
    const values = {};
    if (editedText) {
      values.fullname = userInfo.userName;
      values.directorId = Number(userInfo.userId);
      values.text = editedText;
      addNewComment(values, id).then((res) => {
        handleCloseModal();
        if (!res.Status) handleAfterAddComment(res);
      });
    } else setError(true);
  };

  const handleSetEditedText = (val) => {
    setError(false);
    setEditedText(val);
  };

  return (
    <form>
      {FIELDS.map(({ name, label }) => (
        <div key={name} className="kassa24-textarea mb-4">
          <Editor
            data={editedText}
            onChange={handleSetEditedText}
            label={label}
          />

          {err && <div className="kassa24-input-error">{ERR_FIELD_TEXT}</div>}
        </div>
      ))}
      <Button variant="contained" onClick={sendComment}>
        Добавить
      </Button>
    </form>
  );
};

AddComment.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  handleAfterAddComment: PropTypes.func.isRequired,
};

export default AddComment;
