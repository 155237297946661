import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

import { getClassNames, logout, setToLocalStorage } from 'helpers';
import NoAvatar from 'assets/img/noavatar.png';
import { useUser } from 'hooks';
import AppContext from 'context/AppContext';

const HeadProfile = ({ show }) => {
  const [isShowing, setIsShowing] = useState(show);
  const { userInfo, getUserData, setUserFields } = useUser();
  const { getDirectorProfiles } = useContext(AppContext);
  const [profiles, setUserProfiles] = useState([]);

  const [avatar, setAvatar] = useState();

  useEffect(() => {
    const newData = [];

    getUserData(userInfo).then((res) => {
      if (res.avatar !== '/images/profile.jpg') setAvatar(res.avatar);
    });

    getDirectorProfiles().then((res) => {
      if (res.success) {
        res.data
          .filter((item) => item.idUser !== Number(userInfo.userId))
          .forEach((item) => {
            newData.push(item);
          });
        setUserProfiles(newData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAccount = (user) => {
    const data = { userId: user.id, userLogin: user.login };

    getUserData(data).then((res) => {
      user.userName = user.name;
      user.userId = user.id;
      user.userLogin = user.login;
      user.id = res.id;
      user.other = res;
      setUserFields(user);
      setToLocalStorage('userInfo', user);
      window.location = '/main';
    });
  };

  return (
    <>
      <div className="order-2 pr-2 lg:ml-4 lg:pr-0 lg:flex lg:items-center">
        <Menu as="div" className="ml-3 relative">
          <div>
            <button
              type="button"
              onClick={() => setIsShowing(!isShowing)}
              className="bg-kassa24-active max-w-xs rounded-full flex items-center text-sm  lg:p-2 lg:rounded-md "
            >
              <img
                className="h-8 w-8 rounded-full"
                src={avatar ? avatar : NoAvatar}
                alt="avatar"
                data-testid="avatar"
              />
              <span className="hidden ml-3 text-kassa24-black text-sm font-medium lg:block">
                {userInfo.userName}
              </span>
              <ChevronDownIcon
                className="hidden flex-shrink-0 ml-1 h-5 w-5 text-kassa24-black lg:block"
                aria-hidden="true"
              />
            </button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={isShowing}
            data-testid="profileMenu"
          >
            <Menu.Items className="kassa24-profile-menu">
              <Menu.Item>
                <div className="border-b-2">
                  {profiles.map((account, key) => (
                    <div
                      onClick={() => changeAccount(account)}
                      key={key}
                      data-testid={`user${account.id}`}
                    >
                      <div className="kassa24-profile-account">
                        <div>
                          <img
                            src={NoAvatar}
                            alt="avatar"
                            className="w-7 h-7"
                          />
                        </div>
                        <div className="text-gray-500 text-sm pl-2">
                          {account.groupName}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Menu.Item>

              <Menu.Item>
                <div
                  className={getClassNames(
                    'block px-4 py-2 text-sm text-red-600 cursor-pointer'
                  )}
                  onClick={logout}
                >
                  Выйти
                </div>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

HeadProfile.propTypes = {
  show: PropTypes.bool,
};

HeadProfile.defaultProps = {
  show: false,
};

export default HeadProfile;
