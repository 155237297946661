import React, { useState, useContext, useEffect } from 'react';

import Link from 'components/ui/Link';
import { TERMINAL_PROVIFER_LINK, TERMINAL_TURNOVER_LINK } from 'constants/main';
import { getDate } from 'helpers';
import Table from 'components/ui/Table';
import AppContext from 'context/AppContext';
import ExpectedDateOfStatistic from 'components/ExpectedDateOfStatistic';

const currentDate = getDate('date');

const defaultTurnover = {
  idAgents: 0,
  rentSum: 0,
  commission: 0,
  turnAmount: 0,
  remuneration: 0,
  countPay: 0,
  planComission: 0,
  planTotal: 0,
};

const defaultData = {
  login: '',
  idAgents: 0,
  terminals: [],
  turnover: defaultTurnover,
  oldTurnover: defaultTurnover,
  castTurnover: defaultTurnover,
};

const getFieldValue = (data, fieldName) =>
  (data && moneyFormat(data[fieldName])) ?? '0';
// Percentage calculation
const calculatePercent = (a, b) => Math.floor((a / b) * 100) || 0;
// Color calculation
const getAmoutColor = (amount) => (amount <= 50 ? '#e74c3c' : '#32be3f');

const moneyFormat = (x) => {
  x = Math.round(x);

  if (!x) {
    return 0;
  }
  x = x.toString();
  let p = /(-?\d+)(\d{3})/;
  while (p.test(x)) x = x.replace(p, '$1 $2');

  return x;
};

const TABLE_HEADER_CELLS = [
  { label: 'Финансы', field: 'finance' },
  { label: 'с 1 по ' + currentDate, field: 'periodToCurrentDate' },
  { label: 'Предыдущий месяц', field: 'monthBefore' },
  { label: 'Прогноз на месяц', field: 'monthlyForecast' },
  { label: 'Прогнозируемый прирост', field: 'projectedGrowth' },
];

const generateProblematicAsoTableData = (data = defaultData) => {
  const { turnover, oldTurnover, castTurnover } = data;
  const amount = calculatePercent(
    castTurnover.turnAmount - oldTurnover.turnAmount,
    oldTurnover.turnAmount
  );
  const rentSum = calculatePercent(
    castTurnover.rentSum - oldTurnover.rentSum,
    oldTurnover.rentSum
  );
  const commission = calculatePercent(
    castTurnover.commission - oldTurnover.commission,
    oldTurnover.commission
  );
  const total = calculatePercent(
    castTurnover.planTotal - oldTurnover.planTotal,
    oldTurnover.planTotal
  );
  const colorAmount = ['', '', '', '', getAmoutColor(amount)];
  const colorRentSum = ['', '', '', '', getAmoutColor(rentSum)];
  const colorCommission = ['', '', '', '', getAmoutColor(commission)];
  const colorTotal = ['', '', '', '', getAmoutColor(total)];

  return [
    {
      finance: 'Оборот',
      periodToCurrentDate: getFieldValue(turnover, 'turnAmount'),
      monthBefore: getFieldValue(oldTurnover, 'turnAmount'),
      monthlyForecast: getFieldValue(castTurnover, 'turnAmount'),
      projectedGrowth: `(${amount}%)`,
      colors: colorAmount,
    },
    {
      finance: 'Аренда',
      periodToCurrentDate: getFieldValue(turnover, 'rentSum'),
      monthBefore: getFieldValue(oldTurnover, 'rentSum'),
      monthlyForecast: getFieldValue(castTurnover, 'rentSum'),
      projectedGrowth: `(${rentSum}%)`,
      colors: colorRentSum,
    },
    {
      finance: 'Комиссия',
      periodToCurrentDate: getFieldValue(turnover, 'commission'),
      monthBefore: getFieldValue(oldTurnover, 'commission'),
      monthlyForecast: getFieldValue(castTurnover, 'commission'),
      projectedGrowth: `(${commission}%)`,
      colors: colorCommission,
    },
    {
      finance: 'Доход',
      periodToCurrentDate: getFieldValue(turnover, 'planTotal'),
      monthBefore: getFieldValue(oldTurnover, 'planTotal'),
      monthlyForecast: getFieldValue(castTurnover, 'planTotal'),
      projectedGrowth: `(${total}%)`,
      colors: colorTotal,
    },
    {
      finance: (
        <Link
          href={TERMINAL_TURNOVER_LINK}
          name="Детализация оборота по терминалам"
        />
      ),
    },
    {
      finance: (
        <Link
          href={TERMINAL_PROVIFER_LINK}
          name="Детализация оборота по провайдерам"
        />
      ),
    },
  ];
};

const FinancialStatistics = () => {
  const { fetchFinancialStatistics } = useContext(AppContext);
  const [tableData, setTableData] = useState(generateProblematicAsoTableData());

  useEffect(() => {
    fetchFinancialStatistics().then(({ data: { data } }) => {
      setTableData(generateProblematicAsoTableData(data));
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        tableHead={TABLE_HEADER_CELLS}
        tableData={tableData}
        tableSort={false}
      />
      <ExpectedDateOfStatistic />
    </>
  );
};

export default FinancialStatistics;
