import React from 'react';
import PropTypes from 'prop-types';

import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/outline';
import { getClassNames } from 'helpers';

const TableSortLabel = (props) => {
  const { active, direction, onClick, children } = props;
  return (
    <div>
      <div
        onClick={onClick}
        className={getClassNames(
          active && 'kassa24-active-sort',
          'kassa24-sort-label'
        )}
      >
        <span>{children}</span>
        <span
          className={getClassNames(
            !active && 'opacity-0',
            'kassa24-sort-arrow'
          )}
        >
          {direction === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </span>
      </div>
    </div>
  );
};

TableSortLabel.propTypes = {
  active: PropTypes.bool,
  direction: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string,
};

TableSortLabel.defaultProps = {
  active: false,
  direction: 'asc',
  children: '',
};

export default TableSortLabel;
