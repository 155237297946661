import React from 'react';
import { EXPECTED_DATE_OF_STATISTICS } from 'constants/main';

const ExpectedDateOfStatistic = () => {
  const today = new Date().getDate();

  return (
    <>
      {today <= 7 && (
        <div className="kassa24-small-red-info">
          {EXPECTED_DATE_OF_STATISTICS}
        </div>
      )}
    </>
  );
};

export default ExpectedDateOfStatistic;
