import * as XLSX from 'xlsx';
import * as yup from 'yup';
import * as Sentry from '@sentry/react';
import base64 from 'base-64';
import history from 'appHistory';

export const setToLocalStorage = (name, data) => {
  window.localStorage.setItem(name, JSON.stringify(data));
};

export const getFromLocalStorage = (name) => {
  const value = window.localStorage.getItem(name);
  if (value !== null) {
    return JSON.parse(value);
  }
};

export const redirectToPage = (path) => {
  history.push(`/${path}`);
  return null;
};

export const goToBackPage = () => {
  history.goBack();
};

export const logout = () => {
  setToLocalStorage('token', '');
  setToLocalStorage('userInfo', {});
  window.location = '/';
};

export const setOpenLeftMenuFunc = (open) => {
  setToLocalStorage('showLeftMenu', open);
};

export const imgToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const documentSetTitle = (title) => {
  document.title = title;
};

export const isMenuItemActive = (menu) => window.location.href.includes(menu);

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const sortData = (sortedItems, field, orderType) => {
  sortedItems.sort((a, b) => {
    var nameA = a[field].toLowerCase(),
      nameB = b[field].toLowerCase();
    if (orderType === 'ASC') {
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
    } else {
      if (nameA > nameB) return -1;
      if (nameA < nameB) return 1;
    }
    return 0;
  });

  return sortedItems;
};

export const getFieldsYup = (fields) => {
  var fieldsYup = {};
  fields.forEach((item) => {
    if (item.required) {
      fieldsYup[item.name] = getYupData(item);
    }
  });

  return fieldsYup;
};

const getYupData = (item) => {
  if (item.min)
    return yup
      .string('Введите ' + item.label)
      .min(item.min, 'Длина ' + item.min + ' символов')
      .required('Поле обязательно для заполнения');
  return yup
    .string('Введите ' + item.label)
    .required('Поле обязательно для заполнения');
};

export const getDate = (type, value, format = '', formatType = false) => {
  let date = new Date();

  if (value) {
    if (formatType) date = new Date(value);
    else {
      let editedDate = String(value).split('.');

      date = new Date(
        editedDate[1] + '.' + editedDate[0] + '.' + editedDate[2]
      );
    }
  }

  let edited = '';

  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const monthCurr = date.getMonth() + 1;
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const month = monthCurr < 10 ? '0' + monthCurr : monthCurr;
  const mins =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  const currentTime = hours + ':' + mins;
  const currentDate = day + '.' + month + '.' + date.getFullYear();
  let result = '';

  switch (type) {
    case 'time':
      result = currentTime;
      break;
    case 'date':
      result = currentDate;
      break;
    case 'full':
      result = currentDate + ' ' + currentTime;
      break;
    case 'myear':
      result = month + '.' + date.getFullYear();
      break;
    default:
      result = currentDate;
      break;
  }

  if (format && value) {
    switch (format) {
      case 'yyyymmdd':
        edited = result.split('.');
        result = edited[2] + edited[0] + edited[1];
        break;
      case 'yyyy-mm-dd':
        edited = result.split('.');
        result = edited[2] + '-' + edited[1] + '-' + edited[0];
        break;
      default:
        break;
    }
  }

  return result;
};

export const getPriceFormat = (sum) => {
  return sum && sum.toLocaleString();
};

export const getDiffString = (diff) => {
  let month = '';
  let days = diff;

  if (diff > 365) {
    const year = diff / 365;
    const yearFull = Math.floor(year);

    month = Number(String(year).split('.')[1].substring(0, 1));

    let result = yearFull + ' ' + getStringYear(yearFull);

    if (month > 0) result += ' ' + month + ' ' + getStringMonth(month);

    return result;
  } else if (diff > 31) {
    month = Math.floor(diff / 31);

    return month + ' ' + getStringMonth(month);
  } else {
    days = Math.floor(days);

    return days + ' ' + getStringDays(days);
  }
};

export const getStringYear = (value) => {
  const count = value % 10;
  const yearArr = [2, 3, 4];

  if (yearArr.includes(count)) return 'года';
  else if (count === 1) return 'год';
  else return 'лет';
};

export const getStringMonth = (value) => {
  const count = value % 10;
  const monthArr = [2, 3, 4];

  if (monthArr.includes(count)) return 'месяца';
  else if (count === 1) return 'месяц';
  else return 'месяцев';
};

export const getStringDays = (value) => {
  const count = value % 10;
  const dayArr = [2, 3, 4];

  if (dayArr.includes(count) && (value < 10 || value > 20)) return 'дня';
  else if (count === 1) return 'день';
  else return 'дней';
};

export const exportToXlsx = (cols, id, name) => {
  const workbook = XLSX.utils.table_to_book(document.getElementById(id));

  workbook.Sheets.Sheet1['!cols'] = cols;
  XLSX.writeFile(workbook, `${name}.xls`, {
    bookType: 'xlsx',
    type: 'buffer',
  });
};

export const getValueForSearch = (value) => {
  return String(value).toLowerCase();
};

export function logErrorToSentry(error) {
  if (!window.location.href.includes('localhost')) {
    Sentry.withScope(() => {
      Sentry.captureMessage(error);
    });
  }
}

export function logWarningToSentry(text) {
  if (!window.location.href.includes('localhost')) {
    Sentry.captureMessage(text, 'warning');
  }
}

export function logInfoToSentry(text) {
  if (!window.location.href.includes('localhost')) {
    Sentry.captureMessage(text, 'info');
  }
}

export const getTableFieldValue = (field, value) => {
  if (field === 'MaxDate') {
    return getDate('full', value, '', true);
  }

  if (field === 'SumOld') {
    return getPriceFormat(value);
  }

  return value;
};

export const checkDirection = (row, orderBy, order) => {
  if (orderBy === row.field) {
    return order;
  }

  return 'asc';
};

export const tablCellSortDirection = (orderBy, order, row) => {
  return orderBy === row.field ? order : false;
};

export const getTableCellAlign = (item, k) => {
  if (item.align && item.align[k] !== '') return item.align[k];

  return 'left';
};

export const setWebsocketCommand = (command) => {
  window.localStorage.setItem('ws', JSON.stringify({ command }));
};

export const getWebsocketCommand = () => {
  const initData = {
    command: '',
  };

  if (window.localStorage.getItem('ws') !== null) {
    return JSON.parse(window.localStorage.getItem('ws')).command;
  }

  return initData.command;
};

export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    base64
      .decode(str)
      .split('')
      .map((c) => {
        const code = `00${c.charCodeAt(0).toString(16)}`;
        return `%${code.slice(-2)}`;
      })
      .join('')
  );
}

export const getClassNames = (...classes) => classes.filter(Boolean).join(' ');
export const hasUpperCaseSymbol = (value) => value.match(/(?=.*[A-Z])/);
export const hasLowerCaseSymbol = (value) => value.match(/(?=.*[a-z])/);
export const hasNumberSymbol = (value) => value.match(/(?=.*\d)/);
export const hasSpecialSymbol = (value) => value.match(/(?=.*[^A-Za-z0-9])/);
export const checkErrorMessage = (err) => {
  if (err) {
    if (typeof err.message === 'string') {
      return err.message;
    }

    if (typeof err.error === 'object') {
      return err.error.name;
    } else if (typeof err.error === 'string') {
      return err.error;
    }
  }

  return '';
};
export const makeInterceptorRequestUseConfig = (config) => {
  const authData = getFromLocalStorage('userInfo');
  const token = authData?.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';

  return config;
};
