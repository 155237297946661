export default {
  // Options.jsx
  items_per_page: '',
  jump_to: 'Перейти к',
  jump_to_confirm: '',
  page: 'Страница',

  // Pagination.jsx
  prev_page: 'Предыдущая',
  next_page: 'Следующая',
  prev_5: 'Previous 5 Pages',
  next_5: 'Next 5 Pages',
  prev_3: 'Previous 3 Pages',
  next_3: 'Next 3 Pages',
  page_size: 'Количество на странице',
};
