import { useContext } from 'react';
import UiContext from 'context/UiContext';

const useAlert = () => {
  const context = useContext(UiContext);

  const {
    setSuccessMessage,
    setErrorMessage,
    state: { errorMessage, successMessage },
  } = context;

  return {
    errorMessage,
    successMessage,
    setSuccessMessage,
    setErrorMessage,
  };
};

export default useAlert;
