import React, { useState, useContext, useEffect } from 'react';

import { getDate } from 'helpers';
import Table from 'components/ui/Table';
import AppContext from 'context/AppContext';

const currentDate = getDate('date');

const TABLE_HEADER_CELLS = (datetime) => [
  { label: 'Количество АСО', field: 'totalAsoCount' },
  { label: currentDate, field: 'currentDate' },
  { label: datetime, field: 'withoutDate' },
  { label: 'План', field: 'plan' },
];

const generateProblematicAsoTableData = (data = null) => [
  {
    totalAsoCount: 'Всего терминалов',
    currentDate: data?.countAll ?? 0,
    withoutDate: data?.oldCountAll ?? 0,
    plan: data?.pCountAll ?? 0,
  },
  {
    totalAsoCount: 'В мониторинге терминалов',
    currentDate: data?.countMon ?? 0,
    withoutDate: data?.oldCountMon ?? 0,
    plan: data?.pCountMon ?? 0,
  },
  {
    totalAsoCount: 'Отданных в аренду терминалов',
    currentDate: data?.rentCount ?? 0,
    withoutDate: data?.oldCountNew ?? 0,
    plan: data?.pCountNew ?? 0,
  },
  {
    totalAsoCount: 'Кол-во неактивных терминалов',
    currentDate: data?.countDis ?? 0,
    withoutDate: data?.oldCountDis ?? 0,
    plan: data?.pCountDis ?? 0,
  },
  {
    totalAsoCount: 'Динамика количества неактивных',
    currentDate: data?.countNew ?? 0,
    withoutDate: data?.oldCountNew ?? 0,
    plan: data?.pCountNew ?? 0,
  },
];

const TerminalsStatistic = () => {
  const { fetchTerminalsStatistics } = useContext(AppContext);
  const [tableData, setTableData] = useState(generateProblematicAsoTableData());
  const [oldDatetime, setOldDatetime] = useState(getDate('date'));

  useEffect(() => {
    fetchTerminalsStatistics().then(({ data: { data } }) => {
      setTableData(generateProblematicAsoTableData(data));
      setOldDatetime(getDate('date', data.oldDatetime, '', true));
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      tableHead={TABLE_HEADER_CELLS(oldDatetime)}
      tableData={tableData}
      tableSort={false}
    />
  );
};

export default TerminalsStatistic;
